import React, { useState, useEffect, useRef, useContext } from 'react';
import { PinContext } from '../../App';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { db, upload, storage } from "../../firebase"
import { updateDoc, doc, getDoc,getDocs, setDoc, collection, query, orderBy } from "firebase/firestore"
import ConfirmPin from "../ConfirmPin"
import ReactLoading from 'react-loading';
import {delay} from "../utils/delay"
import {changeMiddleNum} from "../utils/changeMiddleNum"
import {numToBranchNum} from "../utils/numToBranchNum"
import {translateTitle} from "../utils/translateTitle"
import responsiveStyles from "../../Responsive.module.css"


const Problems = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const buildingId = location.pathname.split("/")[2]

    const [pointingImg, setPointingImg] = useState("") 
    const [zumen, setZumen] = useState("")
    const title = location.pathname.split("/")[4]

    const [loadingFlag, setLoadingFlag] = useState(false)
    const [data, setData] = useState([])

    const problemCheckList = 
    [{name: "外部基礎", id: "gaibukiso", alpha: "A"}, 
     {name: "外壁", id: "gaiheki", alpha: "B"}, 
     {name: "軒まわり", id: "nokimawari", alpha: "C"},
     {name: "屋根", id: "yane", alpha: "D"},
     {name: "バルコニー ", id: "balcony", alpha: "E"},
     {name: "敷地外周", id: "shikichigaishuu", alpha: "F"},
     {name: "敷地（建物周囲）", id: "shikichi", alpha: "G"},
     {name: "工作物", id: "kousakubutsu", alpha: "H"},
     {name: "給湯機設備", id: "kyuuyukisetsubi", alpha: "I"},
     {name: "室内床", id: "yuka", alpha: "J"},
     {name: "室内壁", id: "kabe", alpha: "K"},
     {name: "室内天井", id: "tenjou", alpha: "L"},
     {name: "玄関", id: "genkan", alpha: "M"},
     {name: "室内建具", id: "shitsunaitategu", alpha: "N"},
     {name: "外壁側サッシ", id: "gaihekigawassashi", alpha: "O"},
     {name: "設置物等", id: "setsubibutsu", alpha: "P"},
     {name: "小屋裏", id: "koyaura",  alpha: "Q"},
     {name: "各階間天井裏・下屋小屋裏", id: "tenjouurakoyaura", alpha: "R"},
     {name: "床下基礎立ちあがり及び土間", id: "yukashitakiso", alpha: "S"},
     {name: "床下配管", id: "yukashitahaikan", alpha: "T"},
     {name: "床組、土間等", id: "yukagumidoma", alpha: "U"},
     {name: "給水", id: "kyuusui", alpha: "V"},
     {name: "給湯設備", id: "kyuuyusetsubi", alpha: "W"},
     {name: "排水", id: "haisui", alpha: "X"},
     {name: "換気設備", id: "kankisetsubi", alpha: "Y"},
    ]

    useEffect(()=>{
        getPicture()
        getProblemsData() 
    },[])

    async function getPicture(){
        const docRef = doc(db, "buildings", buildingId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            setZumen({src: docSnap.data().pictures?.src, isOriginal: true})
        }
      }

    async function getProblemsData(){
        setLoadingFlag(true)
        console.log("getAllData called")
        let hasProblemLists = []
        let hasProblemListsResult = []
       problemCheckList.forEach(async (item)=>{
         console.log(item.id)
         const collectionRef = collection(db, "buildings", buildingId, "details", "inspection2", item.id);
          const querySnapshot = await getDocs(query(collectionRef, orderBy("order", "asc")));
         //await db.collection("buildings").doc(buildingId).collection("details").doc("inspection2").collection(item.id).orderBy("order", "asc").get()
       //.then((querySnapshot) => {
        
        querySnapshot.forEach(async (doc) => {
           if(doc.data()?.hasProblem==="あり"){
            console.log("HAS PROBLEM")
            hasProblemLists.push(doc.data().problems)
            if(doc.data().problems.length){
              hasProblemListsResult.push({alpha: item.alpha ,...doc.data()})
              /*
              doc.data().problems.forEach(async (problem)=>{
                if(problem.imgUrl){
                  hasProblemListsResult.push({alpha: item.alpha ,...doc.data()})
                  }
              })*/
            }
           }
         });
       }) 
      //})
      console.log(hasProblemListsResult, "hasProblemListsResult")
      setData(hasProblemListsResult)
      await delay(3)
      setLoadingFlag(false)
      }  


      console.log(data, "data")
  return(
    <>
    {loadingFlag ? <ReactLoading type="bubbles" color="#A9A9A9" height={"5%"} width={"80%"} />: 
    <div className={responsiveStyles.frame}>
    <div onClick={()=>{navigate(-1)}} style={{textAlign: "left"}}><p style={{color: "blue", cursor: "pointer"}}>＜ 戻る</p></div>

    <div style={{fontWeight: "bold"}}>不具合報告一覧</div>

    {data.length > 0 ? 
    data.map((com)=>{
      return(
      <>
        <div style={{marginTop: 40, fontWeight: "bold"}}>{com.alpha+". "+com.title}</div>
        <div>{com.hasOwnProperty("page") ? changeMiddleNum(com.page)+". "+com.subtitle : com.subtitle }</div>
            <div>{com.pointingId+". "+com.checItem}</div>
            {com["problems"]?.length > 0 && 
            com["problems"].map((c, i)=>{
              return (
                <>
                  <div  style={{marginTop: 20}}></div>
                  {com["problems"]?.length > 1 && <p>{numToBranchNum(i+1)}</p>}
                  <div onClick={()=>{navigate(`/list/${buildingId}/2/${translateTitle(com.title)}?page=${com.page}`)}}>
                  <img src={c.imgUrl} width="100%"/>
                  </div>
                  <p style={{marginTop: 9, width: "100%"}}>{c.comment }</p>
                </>
              )
            }) }
       
      </>  
      )

    
      
      
    }):<>報告された不具合はありません。</>}

    <button style={{height:50,width: 125,marginTop:50, borderRadius: 15, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}}
          onClick={()=>{
           navigate(-1)
    }}>戻る</button>

    <div style={{marginTop: 50}}></div>
    </div>    
    }
    </>    
  )
}

export default Problems;