import React, { createContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route} from "react-router-dom"
import { AuthProvider } from './AuthProvider';
import PrivateRoute from './Components/PrivateRoute';
import PublicRoute from './Components/PublicRoute';
import Login from "./Pages/Login";
import ShiftForm from "./Pages/ShiftForm";
import List from "./Pages/List";
import Building from "./Components/Building"
import Report from "./Components/Report"

import BuildingTop from "./Components/BuildingTop"
import ShitsugaiBottom from "./Components/ShitsugaiBottom"
import ShitsugaiLeftBottom from "./Components/ShitsugaiLeftBottom"
import ShitsugaiLeft from "./Components/ShitsugaiLeft"
import ShitsugaiLeftTop from "./Components/ShitsugaiLeftTop"
import ShitsugaiTop from "./Components/ShitsugaiTop"
import ShitsugaiRightTop from "./Components/ShitsugaiRightTop"
import ShitsugaiRight from "./Components/ShitsugaiRight"
import ShitsugaiRightBottom from "./Components/ShitsugaiRightBottom"

import Pictuers from "./Components/Pictuers"
import BuildingDocuments from "./Components/BuildingDocuments"

import Kikirui from "./Components/Kikirui"
import Sokuteichousa from "./Components/Sokuteichousa"
import Yane from "./Components/Yane"
import Shitsunai from "./Components/Shitsunai"
import Gaibukiso from "./Components/ComponentsForReport/Gaibukiso"
import GaihekiProblem from "./Components/ComponentsForReport/Gaiheki"
import NokimawariProblem from "./Components/ComponentsForReport/Nokimawari"
import YaneProblem from "./Components/ComponentsForReport/YaneProblem";
import Balcony from "./Components/ComponentsForReport/Balcony"
import Shikichigaishuu from "./Components/ComponentsForReport/Shikichigaishuu"
import Shikichi from "./Components/ComponentsForReport/Shikichi"
import Kousakubutsu from "./Components/ComponentsForReport/Kousakubutsu"
import Kyuuyukisetsubi from "./Components/ComponentsForReport/Kyuuyukisetsubi"
import Yuka from "./Components/ComponentsForReport/Yuka"
import Kabe from "./Components/ComponentsForReport/Kabe"
import Tenjou from "./Components/ComponentsForReport/Tenjou"
import Genkan from "./Components/ComponentsForReport/Genkan"
import Shitsunaitategu from "./Components/ComponentsForReport/Shitsunaitategu"
import Gaihekigawassashi from "./Components/ComponentsForReport/Gaihekigawassashi"
import Setsubibutsu from "./Components/ComponentsForReport/Setsubibutsu"
import Koyaura from "./Components/ComponentsForReport/Koyaura"
import Tenjouurakoyaura from "./Components/ComponentsForReport/Tenjouurakoyaura"
import Yukashitakiso from "./Components/ComponentsForReport/Yukashitakiso"
import Yukashitahaikan from "./Components/ComponentsForReport/Yukashitahaikan"
import Yukagumidoma from "./Components/ComponentsForReport/Yukagumidoma"
import Kyuusui from "./Components/ComponentsForReport/Kyuusui"
import Kyuuyusetsubi from "./Components/ComponentsForReport/Kyuuyusetsubi"
import Haisui from "./Components/ComponentsForReport/Haisui"
import Kankisetsubi from "./Components/ComponentsForReport/Kankisetsubi"

import Problems from "./Components/ComponentsForReport/Problems"

import LevelServey from "./Components/ComponentsForLevelSurvey/NewLevelServey"
import YukaLevel from "./Components/ComponentsForLevelSurvey/YukaLevel"
import KabeLevel from "./Components/ComponentsForLevelSurvey/KabeLevel"

import Finish from "./Components/Finish"

import MyNavbar from "./Components/Navbar"
import './App.css';

export const PinContext = createContext();

function App() {
  const [pins, setPins] = useState([]);
  const addPin = (pinsInfo) => {
    setPins(pinsInfo)
    console.log(pins,"pins")
    const pinsInfoJSON = JSON.stringify(pinsInfo)
    localStorage.setItem("PINS", pinsInfoJSON);
  }

  useEffect(()=>{
    const pinsData = localStorage.getItem('PINS');
    if(pinsData){
      const parsedPinsData = JSON.parse(pinsData)
      setPins(parsedPinsData)
      console.log(pins, "pins in App useEffect")
    }
  },[])
  console.log(pins, "pins inApp")
  return (
    <AuthProvider>
    <PinContext.Provider value={{pins, addPin}}>
    <Router>
      <MyNavbar />
      <Routes>
        <Route path="/" element={<PublicRoute><Login /></PublicRoute>}/>
        <Route path="/list" element={<PrivateRoute><List /></PrivateRoute>} /> 
        <Route path="/list/:buildingId/1" element={<PrivateRoute><Building /></PrivateRoute>}/>
        <Route path="/shiftform" element={<ShiftForm />} /> 

        <Route path="/list/:buildingId/documents" element={<PrivateRoute><BuildingDocuments /></PrivateRoute>}/>

        <Route path="/list/:buildingId/1/building-top" element={<PrivateRoute><BuildingTop /></PrivateRoute>}/>

        <Route path="/list/:buildingId/1/yane" element={<PrivateRoute><Yane /></PrivateRoute>}/>

        <Route path="/list/:buildingId/1/shitsugai-top" element={<PrivateRoute><ShitsugaiTop /></PrivateRoute>}/>
        <Route path="/list/:buildingId/1/shitsugai-right-top" element={<PrivateRoute><ShitsugaiRightTop /></PrivateRoute>}/>
        <Route path="/list/:buildingId/1/shitsugai-right" element={<PrivateRoute><ShitsugaiRight /></PrivateRoute>}/>
        <Route path="/list/:buildingId/1/shitsugai-right-bottom" element={<PrivateRoute><ShitsugaiRightBottom /></PrivateRoute>}/>
        <Route path="/list/:buildingId/1/shitsugai-bottom" element={<PrivateRoute><ShitsugaiBottom /></PrivateRoute>}/>
        <Route path="/list/:buildingId/1/shitsugai-left-bottom" element={<PrivateRoute><ShitsugaiLeftBottom /></PrivateRoute>}/>
        <Route path="/list/:buildingId/1/shitsugai-left" element={<PrivateRoute><ShitsugaiLeft /></PrivateRoute>}/>
        <Route path="/list/:buildingId/1/shitsugai-left-top" element={<PrivateRoute><ShitsugaiLeftTop/></PrivateRoute>}/>  

        <Route path="/list/:buildingId/1/kikirui" element={<PrivateRoute><Kikirui /></PrivateRoute>}/>
        <Route path="/list/:buildingId/1/Sokuteichousa" element={<PrivateRoute><Sokuteichousa /></PrivateRoute>} ></Route>
        {/* 物件毎へ変更 */}
        <Route path="/list/:buildingId/1/shitsunai/:floor/:placeName" element={<PrivateRoute><Shitsunai /></PrivateRoute>}/>

        <Route path="/list/:buildingId/1/pictuers" element={<PrivateRoute><Pictuers /></PrivateRoute>}/>


        <Route path="/list/:buildingId/2" element={<PrivateRoute><Report /></PrivateRoute>}/>
        <Route path="/list/:buildingId/2/gaibukiso" element={<PrivateRoute><Gaibukiso /></PrivateRoute>}/>
        <Route path="/list/:buildingId/2/gaiheki" element={<PrivateRoute><GaihekiProblem /></PrivateRoute>}/>
        <Route path="/list/:buildingId/2/nokimawari" element={<PrivateRoute><NokimawariProblem /></PrivateRoute>}/>
        <Route path="/list/:buildingId/2/yane" element={<PrivateRoute><YaneProblem /></PrivateRoute>}/>
        <Route path="/list/:buildingId/2/balcony" element={<PrivateRoute><Balcony /></PrivateRoute>}/>
        <Route path="/list/:buildingId/2/shikichigaishuu" element={<PrivateRoute><Shikichigaishuu /></PrivateRoute>}/>
        <Route path="/list/:buildingId/2/shikichi" element={<PrivateRoute><Shikichi /></PrivateRoute>}/>
        <Route path="/list/:buildingId/2/kousakubutsu" element={<PrivateRoute><Kousakubutsu /></PrivateRoute>}/>
        <Route path="/list/:buildingId/2/kyuuyukisetsubi" element={<PrivateRoute><Kyuuyukisetsubi /></PrivateRoute>}/>
        <Route path="/list/:buildingId/2/yuka" element={<PrivateRoute><Yuka /></PrivateRoute>}/>
        <Route path="/list/:buildingId/2/kabe" element={<PrivateRoute><Kabe /></PrivateRoute>}/>
        <Route path="/list/:buildingId/2/tenjou" element={<PrivateRoute><Tenjou /></PrivateRoute>}/>
        <Route path="/list/:buildingId/2/genkan" element={<PrivateRoute><Genkan /></PrivateRoute>}/>
        <Route path="/list/:buildingId/2/shitsunaitategu" element={<PrivateRoute><Shitsunaitategu /></PrivateRoute>}/>
        <Route path="/list/:buildingId/2/gaihekigawassashi" element={<PrivateRoute><Gaihekigawassashi/></PrivateRoute>}/>
        <Route path="/list/:buildingId/2/setsubibutsu" element={<PrivateRoute><Setsubibutsu /></PrivateRoute>}/>
        <Route path="/list/:buildingId/2/koyaura" element={<PrivateRoute><Koyaura /></PrivateRoute>}/>
        <Route path="/list/:buildingId/2/tenjouurakoyaura" element={<PrivateRoute><Tenjouurakoyaura /></PrivateRoute>}/>
        <Route path="/list/:buildingId/2/yukashitakiso" element={<PrivateRoute><Yukashitakiso /></PrivateRoute>}/>
        <Route path="/list/:buildingId/2/yukashitahaikan" element={<PrivateRoute><Yukashitahaikan /></PrivateRoute>}/>
        <Route path="/list/:buildingId/2/yukagumidoma" element={<PrivateRoute><Yukagumidoma /></PrivateRoute>}/>
        <Route path="/list/:buildingId/2/kyuusui" element={<PrivateRoute><Kyuusui /></PrivateRoute>}/>
        <Route path="/list/:buildingId/2/kyuuyusetsubi" element={<PrivateRoute><Kyuuyusetsubi /></PrivateRoute>}/>
        <Route path="/list/:buildingId/2/haisui" element={<PrivateRoute><Haisui /></PrivateRoute>}/>
        <Route path="/list/:buildingId/2/kankisetsubi" element={<PrivateRoute><Kankisetsubi /></PrivateRoute>}/>

        <Route path="/list/:buildingId/2/problems" element={<PrivateRoute><Problems /></PrivateRoute>}/>

        <Route path="/list/:buildingId/3" element={<PrivateRoute><LevelServey /></PrivateRoute>}/>
        <Route path="/list/:buildingId/3/yuka-level" element={<PrivateRoute><YukaLevel /></PrivateRoute>}/>
        <Route path="/list/:buildingId/3/kabe-level" element={<PrivateRoute><KabeLevel /></PrivateRoute>}/>

        <Route path="/list/:buildingId/4" element={<PrivateRoute><Finish /></PrivateRoute>}/>
      </Routes>
    </Router>
    </PinContext.Provider>
    </AuthProvider>
  );
}

export default App;
