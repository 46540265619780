import React, { useState, useEffect, useRef, useContext } from 'react';
import { PinContext } from '../../App';
import { useNavigate, useLocation } from 'react-router-dom';
import { db, upload, storage } from "../../firebase"
import { updateDoc, doc, getDoc,getDocs, setDoc, collection } from "firebase/firestore"
import { ref, getDownloadURL } from "firebase/storage";
import PinCircle from '../PinCircle';
import NewPin from "../NewPin"
import ConfirmPin from "../ConfirmPin"
import ReactLoading from 'react-loading';
import {delay} from "../utils/delay"
import {changeMiddleNum} from "../utils/changeMiddleNum"
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import responsiveStyles from "../../Responsive.module.css"

const Gaihekigawassashi =  () => {
  const navigate = useNavigate()
  const location = useLocation();
  const problemPage = location.search.split("?page=")[1]
    const startPage = problemPage ? Number(problemPage): 0
  const buildingId = location.pathname.split("/")[2]
  const title = location.pathname.split("/")[4]
  const [pageFlag, setPageFlag] = useState(startPage)
  const childRef = useRef(0)
  const childRef2 = useRef(0)
  const {pins, addPin} = useContext(PinContext);
  const [isChanged, setIsChanged] = useState(false)
  const [fileSetStatusInPage, setFileSetStatusInPage] = useState(false)

  const usePrevious = (value) => {
    const ref = useRef(value);
      useEffect(() => {
        if(value===6){
          return prevCount
        }
          ref.current = value;
      });
      
      return ref.current;
    
  };
  
  const prevCount = usePrevious(pageFlag);

  /*
    const [problems, setProblems] = useState([
        {id: 1, subtitle: "サッシ・ドア（外壁面）", checkItem: "傾き", problemflag: "なし",  data: [{id :1, file: "", pfile: "", comment: ""}], pointingId: "o1"}, 
        {id: 2, subtitle: "サッシ・ドア（外壁面）",  checkItem: "鍵のかかり具合", problemflag: "なし",  data: [{id :1, file: "", pfile: "", comment: ""}], pointingId: "o2"},
        {id: 3, subtitle: "サッシ・ドア（外壁面）", checkItem: "ドアと枠のすき間",  problemflag: "なし",  data: [{id :1, file: "", pfile: "", comment: ""}], pointingId: "o3"}, 
        {id: 4, subtitle: "サッシ・ドア（外壁面）", checkItem: "開閉具合",  problemflag: "なし",  data: [{id :1, file: "", pfile: "", comment: ""}], pointingId: "o4"},
        {id: 5, subtitle: "サッシ・ドア（外壁面）", checkItem: "開閉時の異音",  problemflag: "なし",  data: [{id :1, file: "", pfile: "", comment: ""}], pointingId: "o5"}, 
        {id: 6, subtitle: "サッシ・ドア（外壁面）", checkItem: "閉めた際のガタつき", problemflag: "なし",  data: [{id :1, file: "", pfile: "", comment: ""}], pointingId: "o6"},
        {id: 7, subtitle: "サッシ・ドア（外壁面）", checkItem: "固定ビスの有無", problemflag: "なし",  data: [{id :1, file: "", pfile: "", comment: ""}], pointingId: "o7"}, 
        {id: 8, subtitle: "サッシ・ドア（外壁面）", checkItem: "２ロックの掛かり具合", problemflag: "なし",  data: [{id :1, file: "", pfile: "", comment: ""}], pointingId: "o8"},
        {id: 9, subtitle: "サッシ・ドア（外壁面）", checkItem: "指詰め防止の具合", problemflag: "なし",  data: [{id :1, file: "", pfile: "", comment: ""}], pointingId: "o9"}, 
        {id: 10, subtitle: "サッシ・ドア（外壁面）", checkItem: "建具・建具枠・網戸・雨戸の傷み", problemflag: "なし",  data: [{id :1, file: "", pfile: "", comment: ""}], pointingId: "o10"},
        {id: 11, subtitle: "サッシ・ドア（外壁面）", checkItem: "網戸の開閉具合", problemflag: "なし", data: [{id :1, file: "", pfile: "", comment: ""}], pointingId: "o11"},
        {id: 12, subtitle: "サッシ・ドア（外壁面）", checkItem: "その他", problemflag: "なし", data: [{id :1, file: "", pfile: "", comment: ""}], pointingId: "o*"}
    ])
    */

    const [problems, setProblems] = useState([
      {id: 1, subtitle: "サッシ・ドア（外壁面）", checkItem: "傾き",problemflag: "なし", page: 1,
      commentOptions: {jisyou:["-", "◯階　◯の東・西・南・北側サッシに傾きがあります。"]}
      ,data: [{id :1, file: "", pfile: "", comment: "", jisyou: "", geninn: "", taisaku: "", hyouka: ""}], pointingId: "o1"}, 
      {id: 2, subtitle: "サッシ・ドア（外壁面）",  checkItem: "鍵のかかり具合", problemflag: "なし", page: 1,
      commentOptions: {jisyou:["-", "◯階　◯の東・西・南・北側サッシの鍵の不具合があります。"]}
      ,data: [{id :1, file: "", pfile: "", comment: "", jisyou: "", geninn: "", taisaku: "", hyouka: ""}], pointingId: "o2"},
      {id: 3, subtitle: "サッシ・ドア（外壁面）", checkItem: "ドアと枠のすき間",  problemflag: "なし", page: 1,
      commentOptions: {jisyou:["-", "◯階　◯の東・西・南・北側サッシに枠とのすき間があります。"]}
      ,data: [{id :1, file: "", pfile: "", comment: "", jisyou: "", geninn: "", taisaku: "", hyouka: ""}], pointingId: "o3"}, 
      {id: 4, subtitle: "サッシ・ドア（外壁面）", checkItem: "開閉具合",  problemflag: "なし", page: 1,
      commentOptions: {jisyou:["-", "◯階　◯の東・西・南・北側サッシの開閉に不具合があります。"]}
      ,data: [{id :1, file: "", pfile: "", comment: "", jisyou: "", geninn: "", taisaku: "", hyouka: ""}], pointingId: "o4"},
      {id: 5, subtitle: "サッシ・ドア（外壁面）", checkItem: "開閉時の異音",  problemflag: "なし", page: 1,
      commentOptions: {jisyou:["-", "◯階　◯の東・西・南・北側サッシの開閉の際に異音があります。"]},
      data: [{id :1, file: "", pfile: "", comment: "", jisyou: "", geninn: "", taisaku: "", hyouka: ""}], pointingId: "o5"},
      {id: 6, subtitle: "サッシ・ドア（外壁面）", checkItem: "閉めた際のガタつき",  problemflag: "なし", page: 1,
      commentOptions: {jisyou:["-", "◯階　◯の東・西・南・北側サッシの開閉の際にガタつきがあります。"]}, 
      data: [{id :1, file: "", pfile: "", comment: "", jisyou: "", geninn: "", taisaku: "", hyouka: ""}], pointingId: "o6"}, 
      {id: 7, subtitle: "サッシ・ドア（外壁面）", checkItem: "固定ビスの有無",  problemflag: "なし", page: 1,
      commentOptions: {jisyou:["-", "◯階　◯の東・西・南・北側サッシの固定ビスがありません。"]},
      data: [{id :1, file: "", pfile: "", comment: "", jisyou: "", geninn: "", taisaku: "", hyouka: ""}], pointingId: "o7"},   
      {id: 8, subtitle: "サッシ・ドア（外壁面）", checkItem: "２ロックの掛かり具合", problemflag: "なし", page: 1,
      commentOptions: {jisyou:["-", "◯階　◯の東・西・南・北側サッシの２ロックに不具合があります。"]},
      data: [{id :1, file: "", pfile: "", comment: "", jisyou: "", geninn: "", taisaku: "", hyouka: ""}], pointingId: "o8"},
      {id: 9, subtitle: "サッシ・ドア（外壁面）", checkItem: "指詰め防止の具合",  problemflag: "なし", page: 1,
      commentOptions: {jisyou:["-", "◯階　◯の東・西・南・北側サッシの指詰め防止に不具合があります。"]},
      data: [{id :1, file: "", pfile: "", comment: "", jisyou: "", geninn: "", taisaku: "", hyouka: ""}], pointingId: "o9"}, 
      {id: 10, subtitle: "サッシ・ドア（外壁面）", checkItem: "建具・建具枠・網戸・雨戸の傷み", problemflag: "なし", page: 1,
      commentOptions: {jisyou:["-", "◯階　◯の東・西・南・北側の外壁建具・外壁建具枠・網戸・雨戸の傷みがあります。"]},
      data: [{id :1, file: "", pfile: "", comment: "", jisyou: "", geninn: "", taisaku: "", hyouka: ""}], pointingId: "o10"}, 
      {id: 11, subtitle: "サッシ・ドア（外壁面）", checkItem: "網戸の開閉具合",  problemflag: "なし", page: 1,
      commentOptions: {jisyou:["-", "◯階　◯の東・西・南・北側の網戸に開閉不具合があります。"]},  
      data: [{id :1, file: "", pfile: "", comment: "", jisyou: "", geninn: "", taisaku: "", hyouka: ""}], pointingId: "o11"}, 
      {id: 12, subtitle: "サッシ・ドア（外壁面）", checkItem: "その他",  problemflag: "なし", page: 1,
      commentOptions: {jisyou:["-"]},  
      data: [{id :1, file: "", pfile: "", comment: "", jisyou: "", geninn: "", taisaku: "", hyouka: ""}], pointingId: "o12"}, 
  ])

    const [pointingImg, setPointingImg] = useState("") 
    const [zumen, setZumen] = useState("")
    const docRef = doc(db, "buildings", buildingId);
    const [loadingFlag, setLoadingFlag] = useState(false)
    const place = "外壁側サッシ"
    const alphaOrder = "O"

    const commentOptions = {
      geninn: ["-", "建築当時から問題があったと思われます。","リフォーム時に問題があったと思われます。","経年変化によるものだと思われます。", "地震・風雨や周辺環境の影響によるものだと思われます。"],
      hyouka: ["-", "構造耐力上の問題が高いと思われます。", "構造耐力上の懸念が一定程度あります。", "構造耐力上の影響は少ないと思われます。", "今後雨水の侵入の可能性があります。", "雨水の侵入の可能性が高いと思われます。", "雨水の侵入の可能性は少ないと思われます。", "結露の可能性が高いと思われます。"],
      taisaku: ["-", "詳細調査の上、補修及び対応が必要です。", "詳細調査をお勧めします。", "補修及び対応をお勧めします。", "経過観察が必要です。", "参考としてご留意ください。"] 
      }

    
      useEffect(()=>{
        async function getPicture(){
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
              setZumen({src: docSnap.data().pictures?.src, isOriginal: true})
          }
        }
        async function getExistData(){
          const collectionRef = collection(db, "buildings", buildingId, "details", "inspection2", title);
          const querySnapshot = await getDocs(collectionRef);
          //console.log([...problems, doc.data()], "problems")
          querySnapshot.forEach((doc) => {
            //console.log(doc.data())
            if(doc.data().hasProblem!=="なし"){
              console.log(doc.id, " => ", doc.data());
              const targetProblem = problems.find(problem=>String(problem.id)===doc.id)
              console.log(targetProblem, "targetProblem")
              targetProblem.problemflag = doc.data().hasProblem
              doc.data().problems.forEach((problem, i)=>{
                if(targetProblem.data.length>i){
                targetProblem.data[i].pfile = problem?.imgUrl
                targetProblem.data[i].comment = problem?.comment
                }else {
                  targetProblem.data.push({id : i+1, pfile: problem?.imgUrl, file: "", comment: problem?.comment, jisyou: "", geninn: "", taisaku: "", hyouka: ""})
                }
              })
              setProblems([...problems])
            }
          });
        }
        getPicture()
        getExistData()
        window.scrollTo(0, 0)
      },[])

    const updateReportdStatus = async () => {
        const buildingDoc = doc(db, "buildings", buildingId, "details", "inspection2")
        await updateDoc(buildingDoc, {gaihekigawassashiReported: true})
    }

    const updateReport = async () => {
      problems.forEach(async (problem)=>{
         console.log(problem, "problem in updateReport") 
         await setDoc(doc(db, "buildings", buildingId, "details", "inspection2", title, String(problem.id)), {title: "外壁側サッシ", subtitle: problem.subtitle, checItem: problem.checkItem, hasProblem: problem.problemflag, order: problem.id, pointingId: problem.pointingId, page: problem.page})   
         if(problem.data){
          let urlArr = []
          for(let i=0; i< problem.data.length; i++){
            if(problem.data[i].file){
              await upload(problem.data[i].pfile ? problem.data[i].pfile : problem.data[i].file, `${buildingId}/reports/${title}/${problem.id}-${i}`)
              const storageRef = ref(storage, `${buildingId}/reports/${title}/${problem.id}-${i}`);
              getDownloadURL(storageRef)
              .then(async (url) => {
                urlArr.push({imgUrl: url, comment: problem.data[i].comment ? problem.data[i].comment : problem.data[i].jisyou + problem.data[i].geninn + problem.data[i].hyouka + problem.data[i].taisaku})
              })
            }else if(typeof problem.data[i].pfile=== "string") {
              urlArr.push({imgUrl: problem.data[i].pfile, comment: problem.data[i].comment})
            }
          }
          
          await delay(2)
          console.log("urlArr", urlArr)
          await updateDoc(doc(db, "buildings", buildingId, "details", "inspection2", title, String(problem.id)), {problems: urlArr})
          await delay(4)
        }    
      })

      if(pointingImg){
        await upload(pointingImg, `${buildingId}/reports/poitingImg`)
        const docRef = doc(db, "buildings", buildingId);
        const starsRef = ref(storage, `${buildingId}/reports/poitingImg`);
        getDownloadURL(starsRef)
        .then(async (url) => {
          await updateDoc(docRef, {pointingImg: url})
        })
      } 
  }

  const handleSecect = (e) => {
      const targetProblem = problems[e.target.id-1]
      targetProblem.problemflag = e.target.value
      setIsChanged(true)
      if(e.target.value==="なし"){
        targetProblem.data = [{id :1, file: "", pfile: "", comment: "", jisyou: "", geninn: "", taisaku: "", hyouka: ""}]
      }
      setProblems([...problems])
     }

   const handleSecect2 = (e) => {
    setIsChanged(true)
    console.log(e.target.id, e.target.size, "e.target") 
    problems[e.target.size-1].data[e.target.id-1][e.target.name] = e.target.value
    problems[e.target.size-1].data[e.target.id-1]["comment"] = ""
    setProblems([...problems])
    console.log(problems,"problems")
 }

 const onFileInputChange = async (e) => {
        setIsChanged(true)
        setFileSetStatusInPage(true)

        problems[e.target.name-1].data[e.target.id-1].file = e.target.files[0]
        setProblems([...problems])

        await delay(2)
        await childRef2.current.parentprocess2()
     }

const setPFiles = (name, id, bolb, circleX, circleY, scaleX, scaleY, circleRotation) => {
 setIsChanged(true)
 console.log(circleX, circleY, scaleX, scaleY, circleRotation, "circleX, circleY, scaleX, scaleY, circleRotation")
  problems[name-1].data[id-1].pfile = bolb
  problems[name-1].data[id-1].circleX = circleX
  problems[name-1].data[id-1].circleY = circleY
  problems[name-1].data[id-1].scaleX = scaleX
  problems[name-1].data[id-1].scaleY = scaleY
  problems[name-1].data[id-1].circleRotation = circleRotation
  setProblems([...problems])
}
     
const generateInputFile = (problemId) =>{
  setIsChanged(true)
   problems[problemId-1].data.push({id : problems[problemId-1].data.length+1, file: "", comment: "", jisyou: "", geninn: "", taisaku: "", hyouka: ""})
   setProblems([...problems])
 }

 const deleteTargetInputFile = (problemId) =>{
   setIsChanged(true)
   problems[problemId-1].data.pop()
   if(problems[problemId-1].data.length===0){
     problems[problemId-1].problemflag = "なし"
   }
   setProblems([...problems])
 }

 const handleText = (e) => {
  setIsChanged(true)
  console.log(e.target.id, e.target.name, "e.target") 
  problems[e.target.name-1].data[e.target.id-1]["comment"] = e.target.value
  setProblems([...problems])
  console.log(problems,"problems")
}

     console.log(problems, "problems")
        
    return (
      <>
      {loadingFlag ? <ReactLoading type="bubbles" color="#A9A9A9" height={"5%"} width={"80%"} />: 
      <div className={responsiveStyles.frame}>
      {pageFlag===0 &&
        <div>
           <p style={{fontWeight: "bold", marginTop: 20}}>{alphaOrder+". "+place}</p>
           <p style={{fontWeight: "bold"}}>調査報告</p> 
           
            {problems.reduce((a, v) => {
              if (!a.some((e) => e.subtitle === v.subtitle)) {a.push(v)}
              return a;
            }, []).map(
              (problem)=>{
                return(
                  <>
                  <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}} onClick={()=>{setPageFlag(problem.page)}} key={problem.id}>
                     <p>{changeMiddleNum(problem.page)+". "+problem.subtitle}</p> 
                    <ArrowCircleRightIcon />
                  </div>
                  <div style={{marginTop:5}}></div>
                  </>
                )
              }
            )}

           
            <div style={{marginTop:20}}></div>

            <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}} onClick={()=>{setPageFlag(2)}}>
              <div>内容確認</div>
              <ArrowCircleRightIcon />
            </div>

            <div style={{marginTop:5}}></div>

            <div style={{display: "flex", justifyContent: "space-around"}}>
                    <button style={{height:50,width: 125,marginTop:50, borderRadius: 15, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}}
                    onClick={()=>{
                      if(isChanged){
                        alert("入力内容の変更がありました。内容確認から報告を完了させてください。")
                      } else {
                        navigate(-1)
                      }
                      }}>戻る</button>
             </div>

             <div style={{marginTop: 50}}></div>  
        </div>
      }
      {pageFlag===1 &&
        <div>
          <p style={{fontWeight: "bold", marginTop: 20}}>調査報告</p> 
          <p style={{fontWeight: "bold", marginTop: 20}}>{alphaOrder+". "+place}</p>
           <p>{changeMiddleNum(pageFlag)+". "}サッシ・ドア（外壁面）</p>  
            {problems.filter((problem)=>problem.subtitle==="サッシ・ドア（外壁面）").map((problem)=>{
               return (
                 <div key={problem.id} style={{marginTop: 50}}>
                   <p>{problem.pointingId+". "+problem.checkItem}</p> 
                   <div style={{marginTop: 35, display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                        <div>不具合</div>
                        <select id={problem.id} value={problem.problemflag} onChange={handleSecect}>
                            <option value={"あり"}>あり</option>
                            <option value={"なし"}>なし</option>
                            <option value={"本物件に該当しない"}>本物件に該当しない</option>
                        </select>
                    </div>

                    {problem.problemflag === "あり" &&
                      <>
                        <div style={{marginTop: 30}}>
                        <p style={{marginBottom: 50}}>画像をアップロードしてください。</p> 
                        </div>
                        
                        {problem.data.map((file)=>{
                           return (
                            <div style={{marginTop: 30}} key={file.id}>
                                <label style={{padding: "10px 20px", color: "#ffffff", backgroundColor: "#696969", cursor: "pointer", borderRadius: 10}}>
                                ファイルを選択
                                <input type="file" name={problem.id} id={file.id} accept="image/*" onChange={onFileInputChange} style={{display: "none"}} />
                                </label>

                                {typeof file.pfile === "string" && file.pfile.length>0 &&
                                <>
                                 <div style={{marginTop:20}}></div> 
                                 <img src={file.pfile} width="100%"/>
                                 <p style={{marginTop: 10}}>コメント</p>
                                 <textarea onChange={handleText} id={file.id} name={problem.id} style={{width: "100%"}} rows="4" value={file.comment && file.comment}></textarea>
                                </>
                                }

                                {file.file ?
                                    <div style={{marginTop: 10}}>
                                   
                                   <>
                                    <PinCircle ref={childRef2} image={window.URL.createObjectURL(file.file)} setFile={setPFiles} id={file.id} name={problem.id} />
                                    <p style={{fontSize: "14px"}}>{file.file.name}</p>
                                    <div>
                                        <p>コメント</p>
                                        <p style={{fontSize: "14px"}}>事象</p>
                                        <select id={file.id} size={problem.id} name="jisyou" onChange={handleSecect2} disabled={problem.data[file.id-1]["comment"]} style={{width:"100%"}}>
                                        <optgroup label="">
                                          {problem.commentOptions.jisyou.map(jisyouOption=><option value={jisyouOption}>{jisyouOption}</option>)}
                                        </optgroup>
                                        </select>

                                        <div style={{marginTop: 15}}></div>
                                        <p style={{fontSize: "14px"}}>原因</p>
                                        <select id={file.id} size={problem.id}  name="geninn" onChange={handleSecect2} disabled={problem.data[file.id-1]["comment"]} style={{width:"100%"}}>
                                        <optgroup label="">
                                          {commentOptions.geninn.map(geninnOption=><option value={geninnOption}>{geninnOption}</option>)}
                                        </optgroup>
                                        </select>

                                        <div style={{marginTop: 15}}></div>
                                        <p style={{fontSize: "14px"}}>評価</p>
                                        <select id={file.id} size={problem.id} name="hyouka" onChange={handleSecect2} disabled={problem.data[file.id-1]["comment"]} style={{width:"100%"}}>
                                        <optgroup label="">
                                         {commentOptions.hyouka.map(hyoukaOption=><option value={hyoukaOption}>{hyoukaOption}</option>)}
                                        </optgroup>
                                        </select>

                                        <div style={{marginTop: 15}}></div>
                                        <p style={{fontSize: "14px"}}>対策</p>
                                        <select id={file.id} size={problem.id} name="taisaku" onChange={handleSecect2} disabled={problem.data[file.id-1]["comment"]} style={{width:"100%"}}>
                                        <optgroup label="">
                                          {commentOptions.taisaku.map(taisakuOption=><option value={taisakuOption}>{taisakuOption}</option>)}
                                        </optgroup>
                                        </select>

                                        <div style={{marginTop: 25}}></div>
                                        <textarea id={file.id} name={problem.id} onChange={handleText} value={problem.data[file.id-1]["comment"] ? problem.data[file.id-1]["comment"] : problem.data[file.id-1]["jisyou"] + problem.data[file.id-1]["geninn"] + problem.data[file.id-1]["hyouka"] + problem.data[file.id-1]["taisaku"]} rows="6" style={{width:"100%", backgroundColor: "#F5F5F5", border: "none", borderRadius: 10,paddingLeft:15, resize: "none" }}></textarea>
                                    </div>
                                    </>
  
                                    </div> 
                                : <></>}

                            </div>
                           ) 
                        })}

                        <div style={{marginTop: 15}}>
                            <button onClick={()=>{ deleteTargetInputFile(problem.id)}}>-</button>
                            <button onClick={()=>{ generateInputFile(problem.id)}}>+</button>
                        </div>
                      </>  
                    }
                 </div>  
               ) 
            })}

            <div style={{display: "flex", justifyContent: "space-around"}}>
                    <button style={{height:50,width: 125,marginTop:50, borderRadius: 15, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}}
                    onClick={()=>{setPageFlag(0)}}>戻る</button>
                    <button style={{height:50,width: 125,marginTop:50, borderRadius: 15, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}}
                    onClick={async ()=>{
                      
                      setPageFlag(2)}}>次へ</button>
             </div>

             <div style={{marginTop: 50}}></div>  
        </div>
      }

      {pageFlag === 2 &&
      <div>
        <p style={{fontWeight: "bold", marginTop: 20}}>内容確認</p> 
        <p>内容を確認し、報告を完了させてください。</p> 
        <p style={{fontWeight: "bold", marginTop: 20}}>{alphaOrder+". "+place}</p>
        {problems.filter(problem=>prevCount===0 || problem.page===prevCount).filter((problem) => problem.problemflag === "あり").length===0 && <p>不具合はありません。</p>}
        {problems.filter(problem=>prevCount===0 || problem.page===prevCount).filter((problem) => problem.problemflag === "あり").map((problem)=>{
           return (
             <div key={problem.id} style={{marginTop: 15}}>
               <div>{changeMiddleNum(problem.page)+". "+problem.subtitle}</div> 
             <p>{problem.pointingId + ". " +problem.checkItem}</p>  
             <div>
                 
             {problem.data.map((file)=>{
                return(
                  
                  <div key={file.id}>
                    {typeof file.pfile === "string" ? 
                    <>
                     <div onClick={()=>{setPageFlag(problem.page)}}>
                       <img src={file.pfile} width="100%" />
                     </div>
                     <p style={{marginTop: 9}}>{file.comment}</p>
                     <div style={{marginBottom: 5}}></div>
                    </> : <></>}
                    { file.file && 
                    <div onClick={()=>{setPageFlag(problem.page)}}>
                      <PinCircle image={window.URL.createObjectURL(file.file)} setFile={setPFiles} id={file.id} name={problem.id} circleX={file.circleX} circleY={file.circleY} scaleX={file.scaleX} scaleY={file.scaleY} circleRotation={file.circleRotation}/>
                      <div style={{marginBottom: 5}}></div>
                    </div>
                    }
                  </div> 
                ) 
             })}
              
             </div>
             </div>  
           ) 
        })}
        
    
    
   
        

        <div style={{display: "flex", justifyContent: "space-around"}}>
         <button style={{height:50,width: 125,marginTop:50, borderRadius: 15, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}}
          onClick={()=>{
            console.log(prevCount, "prevCount")
            setPageFlag(prevCount)
            }}>戻る</button>
         <button style={{height:50,width: 125,marginTop:50, borderRadius: 15, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}}
         onClick={async ()=>{
           setLoadingFlag(true)
           //await childRef.current.parentprocess()
           await updateReportdStatus()
           await updateReport()
           await delay(3)
           setIsChanged(false)
           setLoadingFlag(false)
           if(prevCount===0){
            navigate(`/list/${buildingId}/2`)
            return;  
          }
           setPageFlag(0)
           }}>完了</button>
        </div>

        <div style={{marginTop: 50}}></div>
      </div>
      }
    

     
      
      </div>}</>
    ) };
  
    
  export default Gaihekigawassashi;