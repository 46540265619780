import { useState, useEffect } from "react";
import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword, signOut, onAuthStateChanged } from "firebase/auth"
import { getFirestore } from "@firebase/firestore"
import { getStorage, ref, uploadBytes } from "firebase/storage"

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
  };

  const app = initializeApp(firebaseConfig);

  export const auth = getAuth()

  export const storage = getStorage()

  export const db = getFirestore(app)

  export function signin(email, password) {
    return signInWithEmailAndPassword(auth, email, password)
  }

  export function logout() {
    return signOut(auth)
  }

  // storage

  export async function upload(file, path) {
    const fileRef = ref(storage, `${path}`)
    const snapshot = await uploadBytes(fileRef, file)
  }