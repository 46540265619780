export function changeMiddleNum(num){
   if(num===1){
     return "Ⅰ"  
   } else if(num===2){
    return "Ⅱ"  
   } else if(num===3){
    return "Ⅲ"  
   } else if(num===4){
    return "Ⅳ"  
   } else if(num===5){
    return "V"  
   } else if(num===6){
    return "VI"  
   } else if(num===7){
    return "VII"  
   }  else if(num===8){
    return "VIII"  
   }  else if(num===9){
    return "IX"  
   } else if(num===10){
    return "X"  
   } 
}
