import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import responsiveStyles from "../../Responsive.module.css"
import axios from 'axios'
import ReactLoading from 'react-loading';
import { db, upload, storage } from "../../firebase"
import { updateDoc, doc, getDoc,getDocs, setDoc, collection, query, orderBy } from "firebase/firestore"
import { ref, getDownloadURL, getBlob } from "firebase/storage";
import { delay } from '../utils/delay';
const ExcelJS = require('exceljs');

const NewLevelSurvey = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [loadingFlag, setLoadingFlag] = useState(false)
  const buildingId = location.pathname.split("/")[2]

  const [pontintTemplateSheet, setPontintTemplateSheet] = useState("")
  const [pointingSheet, setPointingSheet] = useState("")
  const [problemPointintImg, setProblemPointingImg] = useState("")
  const [levelPointingImg, setLevelPointingImg] = useState("")
  const [isFileChanged, setIsFileChanged] = useState(false)

  useEffect(()=>{
    getExistingData()
  },[])

  const onFileInputChange = (e) => {
    setIsFileChanged(true)
    if(e.target.name==="problemPointintImg"){
      setProblemPointingImg(e.target.files[0])
    } else if(e.target.name==="levelPointingImg"){
      setLevelPointingImg(e.target.files[0])
    } else if(e.target.name==="pointingSheet"){
      setPointingSheet(e.target.files[0])
    }
 }

 const updateInspectedStatus = async () => {
  const buildingDoc = doc(db, "buildings", buildingId, "details", "inspection3")
  problemPointintImg && await updateDoc(buildingDoc, {kabeLevelReported: true})
  levelPointingImg && await updateDoc(buildingDoc, {yukaevelReported: true})
}

const updateReport = async () => {
  pointingSheet && await upload(pointingSheet, `${buildingId}/documents/pontintSheet.xlsx`)
  problemPointintImg && await upload(problemPointintImg, `${buildingId}/reports/poitingImg`)
  levelPointingImg &&  await upload(levelPointingImg, `${buildingId}/reports/levelPoitingImg`)
  await delay(3)
}
 

  async function getExistingData(){
    const pontintTemplateSheetRef = ref(storage, `pontintTemplateSheet.xlsx`);
    await getDownloadURL(pontintTemplateSheetRef).then(async (url) => {
      setPontintTemplateSheet(url)
    })

    const pointingSheet = ref(storage, `${buildingId}/documents/pontintSheet.xlsx`);
    await getDownloadURL(pointingSheet).then(async (url) => {
      setPointingSheet(url)
    })

    const problemPointintImgRef = ref(storage, `${buildingId}/reports/poitingImg`);
    await getBlob(problemPointintImgRef).then(async (blob) => {
      setProblemPointingImg(blob)
    })

  const levelPointingImgRef = ref(storage, `${buildingId}/reports/levelPoitingImg`);
    await getBlob(levelPointingImgRef).then(async (blob) => {
      setLevelPointingImg(blob)
    })   
  }

  

  return(
    <>
    {loadingFlag ? <ReactLoading type="bubbles" color="#A9A9A9" height={"5%"} width={"80%"} />: 
    <div className={responsiveStyles.frame}>
     <p style={{textAlign: "center", fontWeight: "bold"}}>図面ポインティング</p>
     <p style={{marginTop: 10}}>不具合報告、レベル調査の結果をもとに図面ポインティングを行ってください。</p>
      <p style={{marginTop: 30}}>① 作業用シートをダウンロードしてください。</p>
      <a href={pontintTemplateSheet}>ダウンロードする</a>

      <div style={{marginTop: 60}}></div>
      <p>② 作業シートをアップロードしてください。</p>
      <div style={{marginTop: 30}}></div>

      <label style={{padding: "10px 20px", color: "#ffffff", backgroundColor: "#696969", cursor: "pointer", borderRadius: 10}}>
                                ファイルを選択
          <input type="file" name="pointingSheet" accept=".xlsx" onChange={onFileInputChange} style={{display: "none"}} />
      </label>

      <div style={{marginTop: 30}}>
      {pointingSheet && typeof pointingSheet === "object" && 
        <p>{pointingSheet.name}</p>
      }
      {pointingSheet && typeof pointingSheet === "string" &&
        <a href={pointingSheet}>ポインティング用シート</a>
      }
      </div>

      <div style={{marginTop: 60}}></div>
      <p>③ 作成した画像をアップロードしてください。</p>
      <div style={{marginTop: 30}}></div>
      <p>不具合ポインティング画像</p>
 
      <label style={{padding: "10px 20px", color: "#ffffff", backgroundColor: "#696969", cursor: "pointer", borderRadius: 10}}>
                                ファイルを選択
          <input type="file" name="problemPointintImg" accept="image/*" onChange={onFileInputChange} style={{display: "none"}} />
      </label>

      <div style={{marginTop: 30}}>
      {problemPointintImg && 
      <>
      <img src={typeof problemPointintImg==="string" ? problemPointintImg : window.URL.createObjectURL(problemPointintImg)} width="100%"/>
      </>
      }
      </div>




      <div style={{marginTop: 50}}></div>
      <p>壁・床レベルポインティング画像</p>
      
      <label style={{padding: "10px 20px", color: "#ffffff", backgroundColor: "#696969", cursor: "pointer", borderRadius: 10}}>
                                ファイルを選択
          <input type="file" name="levelPointingImg" accept="image/*" onChange={onFileInputChange} style={{display: "none"}} />
      </label>

      <div style={{marginTop: 30}}>
      {levelPointingImg &&
      <>
      <img src={typeof levelPointingImg==="string" ? levelPointingImg : window.URL.createObjectURL(levelPointingImg)} width="100%"/>
      <div style={{marginTop: 30}} >
      </div>
      </>
      }
      </div>

      <div style={{display: "flex", justifyContent: "space-around"}}>
        {isFileChanged ? 
        <button style={{marginTop:100, height: 50, width: 150, borderRadius: 10, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}} onClick={async ()=>{
          setLoadingFlag(true)
          await updateInspectedStatus()
          await updateReport()
          setLoadingFlag(false)
          navigate(`/list`)
        }}>送信</button> :
        <button style={{marginTop:100, height: 50, width: 150, borderRadius: 10, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}} onClick={()=>{navigate(`/list`)}}>戻る</button> 
        }
      </div>
      <div style={{marginTop: 50}}></div>
    </div>}</>
  );};
    
  export default NewLevelSurvey;