export function numToBranchNum(num){
    if(num===1){
      return "①"  
    } else if(num===2){
     return "②"  
    } else if(num===3){
     return "③"  
    } else if(num===4){
     return "④"  
    } else if(num===5){
     return "⑤"  
    } else if(num===6){
     return "⑥"  
    } else if(num===7){
     return "⑦"  
    }  else if(num===8){
     return "⑧"  
    }  else if(num===9){
     return "⑨"  
    } else if(num===10){
     return "⑩"  
    } 
 }
 