import React, { useContext, useState, useEffect } from 'react';
import { PinContext } from '../../App';
import { useNavigate, useLocation } from 'react-router-dom';
import { db, upload, storage } from "../../firebase"
import { getDoc, doc, updateDoc, collection, addDoc } from "firebase/firestore"
import { ref, getDownloadURL } from "firebase/storage";
import KabeLevelPin from './KabeLevelPin';
import KabeLevelPinResult from "./KabeLevelPinResult"
import responsiveStyles from "../../Responsive.module.css"

const KabeLevel = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const buildingId = location.pathname.split("/")[2]
    const [pageFlag, setPageFlag] = useState(1)
    const [levelInputs, setLevelInputs] = useState([{
      id: "kabe-pin",
      stroke: 'green',
      fill: "green",
      text: 1
    }])
    const [pointingImg, setPointingImg] = useState("") 
    const docRef = doc(db, "buildings", buildingId);
    const [zumen, setZumen] = useState("")
    const {pins, addPin} = useContext(PinContext);
    const [pointing, setPointing] = useState(pins)

    const handle = (e) => {
      const target = pins.find(pin=>pin.id===e.target.id) 
      if(e.target.name==="top"){
        target.topValue = e.target.value
      } else if(e.target.name==="bottom"){
        target.bottomValue = e.target.value
      } else if(e.target.name==="distance"){
        target.distance = e.target.value
      }
      addPin([...pins])
   } 

   const validateInputValue = () => {
    const kabePins = pins.filter(pin=>pin.id.includes("kabe-pin")).filter(kabePin=>!kabePin.id.includes("arrow"))
    console.log(kabePins, "kabePins")
      if(kabePins.length===0) {
        alert("ピンを生成してください。")
        return false
      }
      else {
        const result = kabePins.every(levelInput=> String(levelInput.bottomValue).match(/^\d+$/) && String(levelInput.topValue).match(/^\d+$/) && String(levelInput.distance).match(/^\d+$/))
        if(!result){alert("距離の値が適切でありません。半角数字で入力されているかご確認ください。")}
        return result
      }
  } 

  const calculate = () => {
    const kabePins = pins.filter(pin=>pin.id.includes("kabe-pin"))
    kabePins.filter(levelInput=>!levelInput.id.includes("arrow")).forEach((levelInput)=>{
      const difference = Math.abs(Number(levelInput.bottomValue) - Number(levelInput.topValue))
      levelInput.text = `${Math.floor(difference/Number(levelInput.distance)*1000 * Math.pow( 10, 1 ))/ Math.pow( 10, 1 ) }/1000`
      levelInput.height = 100
      levelInput.width = 600
    })
  }

  const updateReportdStatus = async () => {
    const buildingDoc = doc(db, "buildings", buildingId, "details", "inspection3")
    await updateDoc(buildingDoc, {kabeLevelReported: true})
  }

  const updatePointingImg = async () => {
    if(pointingImg){
      await upload(pointingImg, `${buildingId}/reports/poitingImg`)
      const starsRef = ref(storage, `${buildingId}/reports/poitingImg`);
      getDownloadURL(starsRef)
      .then(async (url) => {
        await updateDoc(docRef, {pointingImg: url})
      })
    } 
  }

    useEffect(()=>{
      async function getPicture(){
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setZumen({src: docSnap.data().pictures?.src, isOriginal: true})
        }
      }
      getPicture()
      window.scrollTo(0, 0)
    },[pageFlag])

      return(
      <div className={responsiveStyles.frame}>
      {pageFlag === 1 && 
        <div>
        <p style={{fontWeight: "bold", marginTop: 20}}>壁レベル調査</p> 
        <p>調査する壁にピンを配置し、上下2箇所の計測値と間の距離を入力してください。</p>
 
        <div style={{marginTop: 30}}></div>
        
        <KabeLevelPin image={zumen?.src} isOriginal={zumen?.isOriginal} setPointingImg={setPointingImg} setPointing={setPointing} pointing={pointing}/>

        {pins.map((pin, i)=>{
           if(pin.id.includes("kabe-pin") && !pin.id.includes("arrow")){
           return(
            <div>
              <div style={{marginTop: 30}}></div>
              <p>{pin.text}.</p>
              <div><p>上</p><input onChange={handle} value={pin?.topValue} name="top" id={pin.id}></input><span> mm</span></div>
              <div style={{marginTop: 9}}><p>下</p><input onChange={handle} value={pin?.bottomValue} name="bottom" id={pin.id}></input><span> mm</span></div>
              <div style={{marginTop: 9}}><p>上下間距離</p><input onChange={handle} value={pin?.distance} name="distance" id={pin.id}></input><span> mm</span></div>
            </div>  
           )}
        })}
        
        <div style={{display: "flex", justifyContent: "space-around"}}>
         <button style={{height:50,width: 125,marginTop:50, borderRadius: 15, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}}
          onClick={()=>{navigate(-1)}}>戻る</button>
         <button style={{height:50,width: 125,marginTop:50, borderRadius: 15, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}}
         onClick={()=>{
           if(validateInputValue()){
             calculate()
             setPageFlag(2)
           }
           }}>次へ</button>
          
         </div>
         <div style={{marginBottom: 50}}></div>
      </div>
      }

      {pageFlag === 2 && 
        <div>
        <p style={{fontWeight: "bold", marginTop: 20}}>壁レベル調査</p> 
        <p style={{fontWeight: "bold"}}>確認画面</p>
        <p>ピンを正しい位置に配置してください。</p>
 
        <KabeLevelPinResult image={zumen?.src} isOriginal={zumen?.isOriginal} setPointingImg={setPointingImg} setPointing={setPointing} pointing={pointing}/>
        
        <div style={{marginTop: 30}}></div>

      

        <div style={{marginTop: 30}}></div>
 
        <div style={{display: "flex", justifyContent: "space-around"}}>
         <button style={{height:50,width: 125,marginTop:50, borderRadius: 15, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}}
          onClick={()=>{setPageFlag(1)}}>戻る</button>
         <button style={{height:50,width: 125,marginTop:50, borderRadius: 15, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}}
         onClick={async ()=>{
          await updatePointingImg()
          await updateReportdStatus()
          navigate(-1)
           }}>完了</button>
        </div>
        <div style={{marginBottom: 50}}></div>
      </div>
      } 
      </div>
    );
  };
    
  export default KabeLevel;