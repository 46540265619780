import React, { useContext } from 'react';
import { PinContext } from '../../App';
import { Stage, Layer, Image, Transformer, Text } from 'react-konva';
import useImage from 'use-image';
import { v4 as uuidv4 } from 'uuid';


const HImage = ({img, width, setScale, setStageHeight}) => {
  const [image] = useImage(img, 'Anonymous');
  const scaleX = width / image?.width 
  setScale(scaleX)
  setStageHeight(scaleX * image?.height)
  return <Image image={image} />;
};

const CustomText = ({ shapeProps, isSelected, onSelect, onChange, func, text, fontSize,setSelectedPin, setTextScale}) => {
  const shapeRef = React.useRef();
  const trRef = React.useRef();

  React.useEffect(() => {
    if (isSelected) {
      // we need to attach transformer manually
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  return (
    <>
      <Text
        fontSize={fontSize}
        text={text}
        onClick={onSelect}
        onTap={onSelect}
        ref={shapeRef}
        {...shapeProps}
        draggable
        onDragEnd={(e) => {
          console.log(e.target, "e.target")
          onChange({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
          });
          func()
        }}
        onTransformEnd={(e) => {
          // transformer is changing scale of the node
          // and NOT its width or height
          // but in the store we have only width and height
          // to match the data better we will reset scale on transform end
          const node = shapeRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();

          console.log(node, "node")

          // we will reset it back
          //node.scaleX(1);
          //node.scaleY(1);
          onChange({
            ...shapeProps,
            x: node.x(),
            y: node.y(),
            // set minimal value
            width: Math.max(node.width() + scaleX),
            height: Math.max(node.height() + scaleY),
            scaleX: scaleX,
            scaleY: scaleY,
            rotation: node.rotation()
          });
        }}
        onTransform={(e) => {
          setTextScale(e.target.attrs.scaleX)
          setSelectedPin(e.target.attrs)
      }}
      />
      {isSelected && (
        <Transformer
          ref={trRef}
          rotateEnabled={text === "ー" || "→"  ? true : false}
          enabledAnchors={ text === "ー" || text === "→" ? ['top-left', 'top-center', 'top-right', 'middle-right', 'middle-left', 'bottom-left', 'bottom-center', 'bottom-right'] :['bottom-right']}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </>
  );
};

const  KabeLevelPinResult = ({image, isOriginal, setPointingImg, setPointing, pointing}) => {
  //const [pins, setPins] = React.useState(levelInputs);
  const {pins, addPin} = useContext(PinContext);
  console.log(pins, "pins")
  const [selectedId, selectShape] = React.useState(null);
  const [selectedPin, setSelectedPin] = React.useState(null);
  const stageRef = React.useRef(null);
  const containerRef  = React.useRef(null);
  const [stageWidth, setStageWidth] = React.useState(250);
  const [stageHeight, setStageHeight] = React.useState(window.innerHeight);
  const [scale, setScale] = React.useState(0.3)
  const [textScale, setTextScale] =  React.useState()
  React.useEffect(()=>{
    let width = containerRef.current.offsetWidth
    setStageWidth(width)
    const kabePins = pins.filter(pin=>pin.id.includes("kabe-pin"))
    console.log(kabePins, "kabePins")
    
    if(!kabePins.some(levelInput=> levelInput.id.includes("arrow"))){
      const arrowNum = kabePins.length
        generateArrow(arrowNum)
    }else{
      const arrowNum = kabePins.filter(levelInput=>levelInput.id.includes("arrow"))
      const levelNum = kabePins.filter(levelInput=>!levelInput.id.includes("arrow"))
      console.log(arrowNum, arrowNum.length)
      console.log(levelNum, levelNum.length)
      if(levelNum.length-arrowNum.length>0){
        console.log("a")
        generateArrow(levelNum.length-arrowNum.length)
      }
    }
    
  },[containerRef])

  const checkDeselect = (e) => {
      selectShape(null);
      setSelectedPin(null)
  };

  const process = async () => {
    checkDeselect()
    const temp = stageRef.current;
    console.log("temp")
 
    temp.absolutePosition({
        x: 0,
        y: 0
      });

    // stageRefの中身(temp )がnullな可能性を考慮してチェック
    if (temp !== null) {
      // dataUrlに、画像データがdata URL(MIME Type + base64文字列)形式で書き込まれる。
      // toDataURLの引数を変更すれば、PNG以外の画像形式への変換も可能
      const dataUrl = temp.toDataURL();
      console.log(dataUrl, "dataUrl")
      var data = atob( dataUrl.substring( "data:image/png;base64,".length ) ),
    asArray = new Uint8Array(data.length);

    for( var i = 0, len = data.length; i < len; ++i ) {
        asArray[i] = data.charCodeAt(i);    
    }

    var blob = new Blob( [ asArray.buffer ], {type: "image/png"} );
    await setPointingImg(blob)
    }
  };

  function generateArrow(num){
    console.log("called", num)
    let result = []
    for(let i=0;i<num;i++){
      const uid = uuidv4();
      const  newArrow = {
        stroke: 'green',
        fill: "green",
        align: "center",
        verticalAlign: "middle",
        id: "kabe-pin-arrow"+uid,
        text: "→"
      }
      result.push(newArrow)
    }
    //setPins([...pins, ...result])
    addPin([...pins,  ...result])
  }

  function increaseSize(){
    console.log("called")
    const deletedTargerPin = pins.find(pin=>pin.id===selectedId)
    console.log(deletedTargerPin, "deletedTargerPin")
    deletedTargerPin["scaleX"] = deletedTargerPin["scaleX"] ? deletedTargerPin["scaleX"]+0.1 : 1.0
    deletedTargerPin["scaleY"] = deletedTargerPin["scaleY"] ? deletedTargerPin["scaleY"]+0.1 : 1.0
    addPin([...pins])
  }

  function decreaseSize(){
    console.log("called")
    const deletedTargerPin = pins.find(pin=>pin.id===selectedId)
    console.log(deletedTargerPin, "deletedTargerPin")
    deletedTargerPin["scaleX"] = deletedTargerPin["scaleX"] ? deletedTargerPin["scaleX"]-0.1 : 1.0
    deletedTargerPin["scaleY"] = deletedTargerPin["scaleY"] ? deletedTargerPin["scaleY"]-0.1 : 1.0
    addPin([...pins])
  }

  function deletePin(){
    const deletedTargerPin = pins.filter(pin=>pin.id!==selectedId)
    //setPins(deletedTargerPin)
    addPin(deletedTargerPin)
  }

  return (
    <div ref={containerRef} >
      {<div style={{textAlign: "right"}}>
        {selectedId && pins.length > 0 ?
        <div style={{display:"flex", justifyContent: "space-between"}}>
        <div>{selectedPin?.hasOwnProperty("scaleX") ? "倍率 ×"+ Math.floor(selectedPin.scaleX * 10)/ 10 :　"-"}</div>
        <div>
        <button onClick={()=>{
          decreaseSize()
          }}>-</button>
        <button onClick={()=>{
          increaseSize()
          }}>+</button>
          <button onClick={()=>{
          deletePin()}}>削除</button>
          </div>
         </div> :
        <div style={{display:"flex", justifyContent: "space-between"}}> 
        <div>-</div>
        <button onClick={()=>{
          }} >ピンを選択してください
        </button> 
        </div>}
      </div>}
    <Stage
     scale={{ x: scale, y: scale }}
      width={stageWidth}
      height={stageHeight}
      ref={stageRef}
    >
      <Layer>
      <HImage img={image} width={stageWidth} setScale={setScale} setStageHeight={setStageHeight} />
        {pins.map((rect, i) => {
          return (
            <CustomText
              key={i}
              text={rect.text}
              shapeProps={{x: stageWidth/scale/2, y:isOriginal ? stageHeight/scale/2+(i*40): stageHeight/scale/2+(i*25), width: isOriginal ? 200: 75, height: isOriginal ? 200: 75, ...rect}}
              isSelected={rect.id === selectedId}
              onSelect={() => {
                selectShape(rect.id);
                const targertPin = pins.find(pin=>pin.id===rect.id)
                setSelectedPin(targertPin)
                setTextScale(targertPin.scaleX)
              }}
              onChange={(newAttrs) => {
                const rects = pins.slice();
                rects[i] = newAttrs;
                //setPins(rects);
                addPin(rects)
              }}
              func={process}
              setSelectedPin={setSelectedPin}
              fontSize={isOriginal ? 100: 36}
              setTextScale={setTextScale}
            />
          );
        })}
      </Layer>
    </Stage>
    </div>
  );
};

export default KabeLevelPinResult;