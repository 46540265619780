export function translateTitle(title){
  const problemLList = 
    [{name: "外部基礎", id: "gaibukiso"}, 
     {name: "外壁", id: "gaiheki"}, 
     {name: "軒まわり", id: "nokimawari"},
     {name: "屋根", id: "yane"},
     {name: "バルコニー ", id: "balcony"},
     {name: "敷地外周", id: "shikichigaishuu"},
     {name: "敷地（建物周囲）", id: "shikichi"},
     {name: "工作物", id: "kousakubutsu"},
     {name: "給湯機設備", id: "kyuuyukisetsubi"},
     {name: "室内床", id: "yuka"},
     {name: "室内壁", id: "kabe"},
     {name: "室内天井", id: "tenjou"},
     {name: "玄関", id: "genkan"},
     {name: "室内建具", id: "shitsunaitategu"},
     {name: "外壁側サッシ", id: "gaihekigawassashi"},
     {name: "設置物等", id: "setsubibutsu"},
     {name: "小屋裏", id: "koyaura"},
     {name: "各階間天井裏・下屋小屋裏", id: "tenjouurakoyaura"},
     {name: "床下基礎立ちあがり及び土間", id: "yukashitakiso"},
     {name: "床下配管", id: "yukashitahaikan"},
     {name: "床組、土間等", id: "yukagumidoma"},
     {name: "給水", id: "kyuusui"},
     {name: "給湯設備", id: "kyuuyusetsubi"},
     {name: "排水", id: "haisui"},
     {name: "換気設備", id: "kankisetsubi"},
    ]

  const target = problemLList.find((problemItem)=>{
    return problemItem.name === title
  })

  console.log(target, "target")

  return target.id
    
 }
 