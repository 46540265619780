import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { db, upload, storage } from "../firebase"
import { updateDoc, doc, getDoc } from "firebase/firestore"
import { ref, getDownloadURL } from "firebase/storage";
import ReactLoading from 'react-loading';
import { v4 as uuidv4 } from 'uuid';
import {delay} from "./utils/delay"
import loadImage from 'blueimp-load-image';

import NoImage from "../images/no_img.png"
import responsiveStyles from "../Responsive.module.css"

const Shitsunai = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const buildingId = location.pathname.split("/")[2]
    const floorKey = location.pathname.split("/")[5]
    const placeKey = location.pathname.split("/")[6]
    const [pageFlag, setPageFlag] = useState(1)
    const [files, setFiles] = useState([""])
    const [loadingFlag, setLoadingFlag] = useState(false)
    const [place, setPlace] = useState({})
    const [isChanged, setIsChanged] = useState(false)
    const [isFileChanged, setIsFileChanged] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [floorImg, setFloorImg] = useState({})

    useEffect(()=>{
      getTargetPlaceData()

      async function getTargetPlaceData(){
        const listCollectionRef = doc(db, "buildings", buildingId)
        const data = await getDoc(listCollectionRef)
        if(floorKey==="1f"){
          setFloorImg(data.data().floorImage1F)
          setPlace(data.data().places1F[placeKey])
          if(data.data().places1F[placeKey].hasOwnProperty("imageUrl")){
            setFiles(data.data().places1F[placeKey].imageUrl)
          }
        }else if (floorKey==="2f"){
          setFloorImg(data.data().floorImage2F)
          setPlace(data.data().places2F[placeKey])
          if(data.data().places2F[placeKey].hasOwnProperty("imageUrl")){
            setFiles(data.data().places2F[placeKey].imageUrl)
          }
        }else if (floorKey==="3f"){
          setFloorImg(data.data().floorImage3F)
          setPlace(data.data().places3F[placeKey])
          if(data.data().places3F[placeKey].hasOwnProperty("imageUrl")){
            setFiles(data.data().places3F[placeKey].imageUrl)
          }
        }
      }
    },[])

    const onFileInputChange = async (e) => {
      setIsChanged(true)
      setIsFileChanged(true)
      
      try{
      const canvas = await loadImage(e.target.files[0], {
        maxWidth: 550,
        canvas: true,
      });
      canvas.image.toBlob((blob) => {
        files[e.target.id] = blob
        setFiles([...files])
      }, e.target.files[0].type);
    }catch(err){
      console.log(err)
    }
      };

    console.log(files, "files")

    
    const updateStatus = async () => {
      if(!place.hasOwnProperty("id")){
        console.log("CALLED updateStatus")
      const buildingDoc = doc(db, "buildings", buildingId)
      const data = await getDoc(buildingDoc)
      const id = uuidv4()
      let allPlaces;
      if(floorKey==="1f"){
        allPlaces = data.data().places1F
      }else if (floorKey==="2f"){
        allPlaces = data.data().places2F
      }else if (floorKey==="3f"){
        allPlaces = data.data().places3F
      }
      
      place["id"] = id
      place["status"] = true
      
      allPlaces[placeKey] = place

      if(floorKey==="1f"){
        await updateDoc(buildingDoc, {places1F: allPlaces})
      }else if (floorKey==="2f"){
        await updateDoc(buildingDoc, {places2F: allPlaces})
      }else if (floorKey==="3f"){
        await updateDoc(buildingDoc, {places3F: allPlaces})
      }
    }
    }

    const updateReport = async () => {
      const buildingDoc = doc(db, "buildings", buildingId)
      const data = await getDoc(buildingDoc)
      let allPlaces;
      if(floorKey==="1f"){
        allPlaces = data.data().places1F
      }else if (floorKey==="2f"){
        allPlaces = data.data().places2F
      }else if (floorKey==="3f"){
        allPlaces = data.data().places3F
      }

      let urlResult = {}
      files.forEach(async (file,i)=>{
        if(typeof file !== "string"){
        await upload(file,`${buildingId}/shitsunai/${floorKey}/${place["id"]}/${i+1}`)
        }
        const storageRef = ref(storage, `${buildingId}/shitsunai/${floorKey}/${place["id"]}/${i+1}`);
          await getDownloadURL(storageRef)
          .then(async (url) => {
              urlResult[i] = url
          })  
      })

      await delay(6)
      place["imageUrl"] = Object.values(urlResult)
      allPlaces[placeKey] = place

      if(floorKey==="1f"){
        await updateDoc(buildingDoc, {places1F: allPlaces})
      }else if (floorKey==="2f"){
        await updateDoc(buildingDoc, {places2F: allPlaces})
      }else if (floorKey==="3f"){
        await updateDoc(buildingDoc, {places3F: allPlaces})
      }
  }

    const generateInputFile = () =>{
      files.push("")
      setFiles([...files])

    }

    const deleteTargetInputFile = () =>{
      files.pop()
      setFiles([...files])
    }


    
    return (
      <>
      {loadingFlag ? <ReactLoading type="bubbles" color="#A9A9A9" height={"5%"} width={"80%"} />: 
       <div className={responsiveStyles.frame}>
      {pageFlag === 1 &&
      <div>
        <p style={{fontWeight: "bold"}}>{place.placeName} 写真撮影</p> 
        <p>{place.placeName}の写真を撮影してください。</p> 

        <img src={floorImg?.src && floorImg?.src} width="80%"/>


        <div style={{marginTop: 30}}>
          <p style={{marginBottom: 50}}>画像をアップロードしてください。</p> 
        </div>
                        
        {files?.length > 0 && files.map((file, i)=>{
           return (
              <div style={{marginTop: 30}} key={i}>
                 <label style={{padding: "10px 20px", color: "#ffffff", backgroundColor: "#696969", cursor: "pointer", borderRadius: 10}}>
                   ファイルを選択
                 <input type="file" id={i} accept="image/*" onChange={onFileInputChange} style={{display: "none"}} />
                 </label>

                  {file &&
                    <div style={{marginTop: 10}}>   
                      <img src={typeof file === "string" ? file : window.URL.createObjectURL(file)} alt="yane_1" width="80%"/>
                    </div>
                  }
              </div>
            ) 
        })}

        <div style={{marginTop: 15}}>
            <button onClick={()=>{deleteTargetInputFile()}}>-</button>
            <button onClick={()=>{generateInputFile()}}>+</button>
        </div>
    
        <div style={{display: "flex", justifyContent: "space-around"}}>
         <button style={{height:50,width: 125,marginTop:50, borderRadius: 15, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}}
          onClick={()=>{navigate(-1)}}>戻る</button>
         <button style={{height:50,width: 125,marginTop:50, borderRadius: 15, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}}
         onClick={()=>{setPageFlag(2)}}>次へ</button>
        </div>

        <div style={{marginTop: 50}}></div>  
      </div>
      }
      {pageFlag === 2 &&
      <div>
        <p style={{fontWeight: "bold"}}>{place.placeName} 写真確認</p> 
        <p>{place.placeName}の写真撮影が終わりました。以下の画像で間違いないかご確認ください。</p> 
        {console.log(files, "files")}

        {files[0] ?
        files.map((file, i)=>{
          return (
          <div key={i}>
            {file && <img src={typeof file === "string" ? file : window.URL.createObjectURL(file)} alt="yane_1" width="80%"/>}
            <div style={{marginBottom: 5}}></div>
          </div>
        )
        }): <img src={NoImage} alt="no-image"/>
        
        }

        {isEdit ? 
        <>
          {isChanged ?
          <button style={{height:50,width: 125,marginTop:50, borderRadius: 15, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}}
          onClick={async ()=>{
           setLoadingFlag(true)
            await updateReport()
            setLoadingFlag(false)
            navigate(-1)
            }}>完了</button>: 
          <button style={{height:50,width: 125,marginTop:50, borderRadius: 15, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}}
          onClick={()=>{navigate(-1)}}>戻る</button>
          }
        </>:
        <div style={{display: "flex", justifyContent: "space-around"}}>
         <button style={{height:50,width: 125,marginTop:50, borderRadius: 15, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}}
          onClick={()=>{setPageFlag(1)}}>戻る</button>
         <button style={{height:50,width: 125,marginTop:50, borderRadius: 15, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}}
         onClick={async ()=>{
          setLoadingFlag(true)
           await updateStatus()
           await updateReport()
           setLoadingFlag(false)
           navigate(-1)
           }}>完了</button>
        </div>
        }

        <div style={{marginTop: 50}}></div>  
      </div>
      }
      </div>}</>
    );
  };
    
  export default Shitsunai;