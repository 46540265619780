import React, { useState, useEffect, useRef, useContext } from 'react';
import { PinContext } from '../../App';
import { useNavigate, useLocation } from 'react-router-dom';
import { db, upload, storage } from "../../firebase"
import { getDoc, doc, updateDoc, collection, addDoc } from "firebase/firestore"
import { ref, getDownloadURL } from "firebase/storage";
import LevelPin from './LevelPin';
import LevelPinResult from './LevelPinResult';
import LevelArrowResult from "./LevelArrowResult"
import { v4 as uuidv4 } from 'uuid';
import responsiveStyles from "../../Responsive.module.css"

const YukaLevel = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const buildingId = location.pathname.split("/")[2]
    const listCollectionRef = doc(db, "buildings", buildingId, "details", "inspection2")
    const [pageFlag, setPageFlag] = useState(1)
    const [pointingImg, setPointingImg] = useState("") 
    const [zumen, setZumen] = useState("")
    const [distance, setDistance] = useState({})
    const [isCalculated, setIsCalculated] = useState(false)
    const [tilts, setTilts] = useState([])
    const docRef = doc(db, "buildings", buildingId);
    const {pins, addPin} = useContext(PinContext);
    const [pointing, setPointing] = useState(pins)
    
    const handle = (e) => {
       console.log(e.target.id, "e.target.id")
       if(e.target.id.includes("distance")){
        //setDistance(e.target.value)
        distance[e.target.id] = e.target.value
        setDistance(distance)
        addPin([...pins])

       }else{
       const target = pins.find(levelInput=>levelInput.id===e.target.id) 
       /*
       if (e.target.value.match(/[0-9]{1,4}/g) != e.target.value ) {
        form.value = '';
       }
       */
       target.value = e.target.value
       //target.text = e.target.value
       console.log(target, "target")
       addPin([...pins])
      }
    } 


    console.log(distance, "distance")

    const validateInputValue = () => {
      const yukaPins = pins.filter(pin=>pin.id.includes("yuka-pin"))
      if(yukaPins.length===0) {
        alert("ピンを生成してください。")
        return false
      } else {
        const result = yukaPins.every(levelInput=> String(levelInput.value).match(/^\d+$/))
        const distanceChecked = Object.values(distance).every(value=> value.match(/^\d+$/))
        console.log(result, distanceChecked)
        if(!result){alert("計測値の値が適切でありません。半角数字で入力されているかご確認ください。")}
        if(!distanceChecked){alert("距離の値が適切でありません。半角数字で入力されているかご確認ください。")}
        if(!isCalculated){alert("傾きの計算が行われていません。値の入力後『計算』を押し、結果をご確認ください。")}
        return result && distanceChecked
      }
    } 

    const validateDistanceInputValue = () => {
      const result = String(distance).match(/^\d+$/)
      if(!result){alert("距離の値が適切でありません。半角数字で入力されているかご確認ください。")}
      return result
    }


    const calculate = () => {
        //initilize()
        //console.log(levelInputs, "levelInputs")
        const yukaPins = pins.filter(pin=>pin.id.includes("yuka-pin"))
        console.log(yukaPins, "yukaPins")
        let minValue = Number(yukaPins[0].value)
        yukaPins.forEach((yukaPin)=>{
          console.log(yukaPin.value, "yukaPin.value")
           if(minValue>Number(yukaPin.value)){
            minValue = Number(yukaPin.value)
           }  
        })
        console.log(minValue, "minValue")

        yukaPins.forEach((yukaPin)=>{
            if(Number(yukaPin.value)-minValue===0){
              yukaPin.text = "±0" 
            }else {
              yukaPin.text = "-" + (Number(yukaPin.value)-minValue)  
            }
         })
    }

    const calculateEachTilt = () => {
      const yukaPins = pins.filter(pin=>pin.id.includes("yuka-pin"))
      console.log(yukaPins, "yukaPins")
      const pairs = Object.entries(distance);
      pairs.sort(function(p1, p2){
        var p1Key = p1[0], p2Key = p2[0];
        if(p1Key < p2Key){ return -1; }
        if(p1Key > p2Key){ return 1; }
        return 0;
      })
      const sortedDistance = Object.fromEntries(pairs);
      console.log(sortedDistance, "sortedDistance");
      const distances = Object.values(sortedDistance)
      console.log(distances, "distances")

      let result = []
      let maxValue = 0;
      let targetObj;
      for(let i=0;i<yukaPins.length; i++){
        if(i===yukaPins.length-1){
          if(maxValue<Math.round(Math.abs((Number(yukaPins[i].value)-Number(yukaPins[0].value))) / Number(distances[i])*1000*10)/10){
            maxValue =Math.round(Math.abs((Number(yukaPins[i].value)-Number(yukaPins[0].value))) / Number(distances[i])*1000*10)/10
            targetObj={id: i, distance: distances[i],tilt: Math.round(Math.abs((Number(yukaPins[i].value)-Number(yukaPins[0].value))) / Number(distances[i])*1000*10)/10}
          } 
          console.log(Number(yukaPins[i].value), Number(yukaPins[0].value), Number(distances[i]))
          result.push({id: i, distance: distances[i],tilt: Math.round(Math.abs((Number(yukaPins[i].value)-Number(yukaPins[0].value))) / Number(distances[i])*1000*10)/10})
        }else{
        if(maxValue<Math.round(Math.abs((Number(yukaPins[i].value)-Number(yukaPins[i+1].value)))  / Number(distances[i])*1000*10)/10){
          maxValue =　Math.round(Math.abs((Number(yukaPins[i].value)-Number(yukaPins[i+1].value)))  / Number(distances[i])*1000*10)/10
          targetObj={id: i, distance: distances[i],tilt:Math.round(Math.abs((Number(yukaPins[i].value)-Number(yukaPins[i+1].value)))  / Number(distances[i])*1000*10)/10 }
        }  
        console.log(Number(yukaPins[i].value), Number(yukaPins[i+1].value), Number(distances[i]))
        result.push({id: i, distance: distances[i],tilt:Math.round(Math.abs((Number(yukaPins[i].value)-Number(yukaPins[i+1].value)))  / Number(distances[i])*1000*10)/10 })
        }


        const uuid = uuidv4()

        const  newArrow = {
          x: 100,
          y: 100,
          stroke: 'blue',
          fill: "blue",
          id: "arrow"+pins.length+1,
          text: "→"
        }

        const distancePin = {
          x: 100,
          y: 100,
          height:  100,
          width: zumen?.isOriginal ? 500 : 150,
          align: "center",
          verticalAlign: "middle",
          stroke: 'blue',
          fill: "blue",
          id: "distance"+uuid,
          text: targetObj.distance+"mm"
       }


       const pinResult = {
        x: 100,
        y: 200,
        height:  100,
        width: zumen?.isOriginal ? 500 : 150,
        align: "center",
        verticalAlign: "middle",
        stroke: 'blue',
        fill: "blue",
        id: "result"+uuid,
        text: targetObj.tilt + "/1000"
     }
        
        console.log(targetObj, "targetObj")
        setIsCalculated(true)
        setTilts(result)
        addPin([...pins, newArrow, distancePin, pinResult ])
      }
    }

    const calculateDistance = () => {
      const yukaPins = pins.filter(pin=>pin.id.includes("yuka-pin"))
      const uuid = uuidv4()
      if(!yukaPins.some(yukaPin=>yukaPin.id.includes("result"))){
      console.log("called")
      let minValue = Number(yukaPins[0].value);
      let maxValue = 0;
      //let dValue = Number(distance)

      yukaPins.forEach((yukaPin)=>{
          if(minValue>Number(yukaPin.value)){
            minValue = Number(yukaPin.value) 
           } 
          if(maxValue<Number(yukaPin.value)){
            maxValue = Number(yukaPin.value)
          } 
      })
      
      /*
      const distancePin = {
        x: 100,
        y: 100,
        height:  100,
        width: zumen?.isOriginal ? 500 : 150,
        align: "center",
        verticalAlign: "middle",
        stroke: 'blue',
        fill: "blue",
        id: "distance"+uuid,
        text: dValue+"mm"
     }

     console.log(maxValue, "-", minValue, "/", dValue, 1000, "*", 10)

       const result = {
          x: 100,
          y: 200,
          height:  100,
          width: zumen?.isOriginal ? 500 : 150,
          align: "center",
          verticalAlign: "middle",
          stroke: 'blue',
          fill: "blue",
          id: "result"+uuid,
          text: Math.round((maxValue-minValue)/dValue*1000 * 10) /10 + "/1000"
       }

       addPin([...pins, distancePin, result ])
       */
      }
      }
      const updateReportdStatus = async () => {
        const buildingDoc = doc(db, "buildings", buildingId, "details", "inspection3")
        await updateDoc(buildingDoc, {yukaLevelReported: true})
      }

      const updatePointingImg = async () => {
        if(pointingImg){
          await upload(pointingImg, `${buildingId}/reports/poitingImg`)
          const starsRef = ref(storage, `${buildingId}/reports/poitingImg`);
          getDownloadURL(starsRef)
          .then(async (url) => {
            await updateDoc(docRef, {pointingImg: url})
          })
        } 
      }

    useEffect(()=>{
        async function getPicture(){
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
              setZumen({src: docSnap.data().pictures?.src, isOriginal: true})
          }
        }
        getPicture()
        window.scrollTo(0, 0)
      },[pageFlag])


 





    return (
      <div className={responsiveStyles.frame}>
      {pageFlag === 1 && 
        <div>
        <p style={{fontWeight: "bold", marginTop: 20}}>床レベル調査</p> 
        <p>1部屋に対してピンを必要な数だけ作成・配置し、計測値を入力してください。</p>
        <p>値の入力後『計算』を押すと、傾きを求めることができます。</p>
 
        <LevelPin image={zumen?.src} isOriginal={zumen?.isOriginal} setPointingImg={setPointingImg} setPointing={setPointing} pointing={pointing} />

        <div style={{marginTop: 30}}></div>

        <div style={{textAlign: "right"}}>
         <button onClick={()=>{
           calculateEachTilt()
           window.scrollTo(0, 0);
           }}>計算</button>  
        </div>
        
        {pins.map((pin, i)=>{
           console.log(pin, "yuka-pin") 
           if(pin.id.includes("yuka-pin")){
           return(
            <div onChange={handle}>
              <span>{i+1}. </span><input value={pin?.value} id={pin.id}></input><span> mm</span>
            </div>  
           )}
        })}

        <div style={{marginTop: 30}}></div>

        {pins.filter(pin=>pin.id.includes("yuka-pin")).map((pin, i)=>{
           console.log(pin, "yuka-pin") 
           return(
            <div onChange={handle}>
              <span>{i === pins.filter(pin=>pin.id.includes("yuka-pin")).length - 1 ? (i+1)+"-"+1 : (i+1)+"-"+(i+2)}間距離. </span><input value={i === pins.filter(pin=>pin.id.includes("yuka-pin")).length - 1 ? distance["distance"+(i+1)+"-"+1] :   distance["distance"+(i+1)+"-"+(i+2)]} id={i === pins.filter(pin=>pin.id.includes("yuka-pin")).length - 1 ? "distance"+(i+1)+"-"+1 : "distance"+(i+1)+"-"+(i+2)}></input><span> mm</span>
              <div>{tilts.length>0 && "傾き -> " + tilts[i].tilt}</div>
            </div>  
           )}
           )}
        
        <div style={{display: "flex", justifyContent: "space-around"}}>
         <button style={{height:50,width: 125,marginTop:50, borderRadius: 15, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}}
          onClick={()=>{navigate(-1)}}>戻る</button>
         <button style={{height:50,width: 125,marginTop:50, borderRadius: 15, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}}
         onClick={()=>{
             if(validateInputValue() && isCalculated){
              setPageFlag(2)
              calculate()
             }
          }}>次へ</button>
        </div>

        <div style={{marginBottom: 30}}></div>
      </div>
      }

      {pageFlag === 2 && 
        <div>
        <p style={{fontWeight: "bold", marginTop: 20}}>床レベル調査</p> 
        <p style={{fontWeight: "bold", marginTop: 20}}>確認画面</p> 
        <p>ピンを正しい位置に配置してください。</p>
 
        <LevelPin image={zumen?.src} isOriginal={zumen?.isOriginal} setPointingImg={setPointingImg} setPointing={setPointing} pointing={pointing} />

        <div style={{marginTop: 30}}></div>

        <div style={{display: "flex", justifyContent: "space-around"}}>
         <button style={{height:50,width: 125,marginTop:50, borderRadius: 15, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}}
          onClick={()=>{
            setPageFlag(1)
            }}>戻る</button>
         <button style={{height:50,width: 125,marginTop:50, borderRadius: 15, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}}
         onClick={async ()=>{
           await updatePointingImg()
           await updateReportdStatus()
           navigate(-1)
          }}>完了</button>
        </div>

        <div style={{marginBottom: 30}}></div>
      </div>
      } 
      </div>
    );
  };
    
  export default YukaLevel;