import React, { useState } from 'react';
import '../lib/css/mobiscroll.react.min.css';
import { Datepicker, localeJa } from '@mobiscroll/react';
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import { db, upload, storage } from "../firebase"
import { updateDoc,setDoc, doc, getDoc, collection } from "firebase/firestore"

import responsiveStyles from "../Responsive.module.css"

const Finish = () => {
  const navigate = useNavigate()
  const location = useLocation();
  const buildingId = location.pathname.split("/")[2]
  const [workDateTime, setWordDateTime] = useState("")
  const [weather, setWeather] = useState("晴れ")

  const reportFinish = async () => {
    const buildingDoc = doc(db, "buildings", buildingId)
    await updateDoc(buildingDoc, {inspectionDate: workDateTime, weather: weather, isFinished: true})
  }

  const handleChange = (e) =>{
    setWeather(e.target.value)
  }

  console.log(weather, "weather")

  return (
    <div className={responsiveStyles.frame}>
      <p style={{fontWeight: "bold"}}>検査完了報告</p>

      <div style={{marginTop: 40}}>
      <p>検査時間帯</p>
        <Datepicker
            controls={['calendar', 'time']}
            select="range"
            touchUi={true}
            locale={localeJa}
            dayNamesMin={['日', '月', '火', '水', '木', '金', '土']}
            monthNames={['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']}
            rangeStartLabel="開始時刻"
            rangeStartHelp="入力してください"
            rangeEndLabel="終了時刻"
            rangeEndHelp="入力してください"
            setText="設定"
            cancelText="キャンセル"
            dateFormat='YYYY/MM/DD'
            onChange={function (event, inst) {
              //const date = {...inspectionDateTime,inspectionDateTime2: event.value }
              //setInspectionDateTime(date)
              console.log(event.valueText)
              setWordDateTime(event.valueText)
           }}
        />
      </div>

      <div>
        <p>天候</p>
        <select onChange={handleChange} style={{width: "80%"}}>
          <option value={"晴れ"}>晴れ</option>
          <option value={"雨"}>雨</option>
          <option value={"曇り"}>曇り</option>
        </select>
      </div>

      <div style={{marginTop: 40, display: "flex", justifyContent: "space-around"}}>
         <button style={{height:50,width: 125,marginTop:50, borderRadius: 15, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}}
          onClick={()=>{navigate(`/list/`)}}>戻る</button>
         <button style={{height:50,width: 125,marginTop:50, borderRadius: 15, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}}
         onClick={async ()=>{
           if(weather!=="" && workDateTime!==""){
           await reportFinish()
           navigate(`/list/`)
          }else{
            alert("送信に失敗しました。入力内容を確認の上、もう一度お試しください。")
          }
           }}>完了</button>
        </div>
      </div>  
            
 
  )  
}

export default Finish;