import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { db } from "../firebase"
import { getDoc, doc, updateDoc } from "firebase/firestore"
import responsiveStyles from "../Responsive.module.css"

const Report = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const buildingId = location.pathname.split("/")[2]
    const listCollectionRef = doc(db, "buildings", buildingId, "details", "inspection2")
    const [list, setList] = useState({})

    useEffect(()=>{
      const getList = async () =>{
        const data = await getDoc(listCollectionRef)
        let filteredObj = {}
        const obj = data.data()
        for(let key in obj){
           if(key.includes("Reported")) filteredObj[key] = obj[key]
        }
        setList(filteredObj)
      }
      getList()
    }, [])

    console.log(list, "lists")

    return (
      <div className={responsiveStyles.frame}>
        <Link to={`/list`}><div style={{textAlign: "left"}}>戻る</div></Link>   
        <p style={{textAlign: "center", fontWeight: "bold"}}>調査報告</p>
        <p>調査報告をおこなっていきます。以下の順序でおこなって下さい。</p>
      
        <div style={{marginTop: 40}}>
        <Link to={`/list/${buildingId}/2/problems`}>
            <div style={{textAlign: "right"}}>不具合報告一覧</div>
        </Link>
        <div style={{display:"flex", justifyContent:"space-between",alignItems: "center", marginTop:20}}>
          <p>A.外部基礎</p>
          {list?.gaibukisoReported ? <button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#ccc", color: "white", borderColor: "#ccc", cursor: "pointer"}} onClick={()=>{navigate("gaibukiso")}}>再報告する</button>:<button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}} onClick={()=>{navigate("gaibukiso")}}>報告する</button>}
        </div>
        <div style={{display:"flex", justifyContent:"space-between",alignItems: "center", marginTop:20}}>
          <p>B.外壁</p>
          {list?.gaihekiReported ? <button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#ccc", color: "white", borderColor: "#ccc", cursor: "pointer"}} onClick={()=>{navigate("gaiheki")}}>再報告する</button>:<button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}} onClick={()=>{navigate("gaiheki")}}>報告する</button>}
        </div>
        <div style={{display:"flex", justifyContent:"space-between",alignItems: "center", marginTop:20}}>
          <p>C.軒まわり</p>
          {list?.nokimawariReported ? <button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#ccc", color: "white", borderColor: "#ccc", cursor: "pointer"}} onClick={()=>{navigate("nokimawari")}}>再報告する</button>:<button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}} onClick={()=>{navigate("nokimawari")}}>報告する</button>}
        </div>
        <div style={{display:"flex", justifyContent:"space-between",alignItems: "center", marginTop:20}}>
          <p>D.屋根</p>
          {list?.yaneReported ? <button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#ccc", color: "white", borderColor: "#ccc", cursor: "pointer"}} onClick={()=>{navigate("yane")}}>再報告する</button>:<button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}} onClick={()=>{navigate("yane")}}>報告する</button>}
        </div>
        <div style={{display:"flex", justifyContent:"space-between",alignItems: "center", marginTop:20}}>
          <p>E.バルコニー</p>
          {list?.balconyReported ? <button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#ccc", color: "white", borderColor: "#ccc", cursor: "pointer"}} onClick={()=>{navigate("balcony")}}>再報告する</button>:<button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}} onClick={()=>{navigate("balcony")}}>報告する</button>}
        </div>
        <div style={{display:"flex", justifyContent:"space-between",alignItems: "center", marginTop:20}}>
          <p>F.敷地外周</p>
          {list?.shikichigaishuuReported ? <button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#ccc", color: "white", borderColor: "#ccc", cursor: "pointer"}} onClick={()=>{navigate("shikichigaishuu")}}>再報告する</button>:<button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}} onClick={()=>{navigate("shikichigaishuu")}}>報告する</button>}
        </div> 
        <div style={{display:"flex", justifyContent:"space-between",alignItems: "center", marginTop:20}}>
          <p>G.敷地（建物周囲）</p>
          {list?.shikichiReported ? <button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#ccc", color: "white", borderColor: "#ccc", cursor: "pointer"}} onClick={()=>{navigate("shikichi")}}>再報告する</button>:<button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}} onClick={()=>{navigate("shikichi")}}>報告する</button>}
        </div>
        <div style={{display:"flex", justifyContent:"space-between",alignItems: "center", marginTop:20}}>
          <p>H.工作物</p>
          {list?.kousakubutsuReported ? <button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#ccc", color: "white", borderColor: "#ccc", cursor: "pointer"}} onClick={()=>{navigate("kousakubutsu")}}>再報告する</button>:<button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}} onClick={()=>{navigate("kousakubutsu")}}>報告する</button>}
        </div>
        <div style={{display:"flex", justifyContent:"space-between",alignItems: "center", marginTop:20}}>
          <p>I.給湯機設備</p>
          {list?.kyuuyukisetsubiReported ? <button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#ccc", color: "white", borderColor: "#ccc", cursor: "pointer"}} onClick={()=>{navigate("kyuuyukisetsubi")}}>再報告する</button>:<button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}} onClick={()=>{navigate("kyuuyukisetsubi")}}>報告する</button>}
        </div>
        <div style={{display:"flex", justifyContent:"space-between",alignItems: "center", marginTop:20}}>
          <p>J.室内床</p>
          {list?.yukaReported ? <button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#ccc", color: "white", borderColor: "#ccc", cursor: "pointer"}} onClick={()=>{navigate("yuka")}}>再報告する</button>:<button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}} onClick={()=>{navigate("yuka")}}>報告する</button>}
        </div>
        <div style={{display:"flex", justifyContent:"space-between",alignItems: "center", marginTop:20}}>
          <p>K.室内壁</p>
          {list?.kabeReported ? <button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#ccc", color: "white", borderColor: "#ccc", cursor: "pointer"}} onClick={()=>{navigate("kabe")}}>再報告する</button>:<button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}} onClick={()=>{navigate("kabe")}}>報告する</button>}
        </div>
        <div style={{display:"flex", justifyContent:"space-between",alignItems: "center", marginTop:20}}>
          <p>L.室内天井</p>
          {list?.tenjouReported ? <button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#ccc", color: "white", borderColor: "#ccc", cursor: "pointer"}} onClick={()=>{navigate("tenjou")}}>再報告する</button>:<button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}} onClick={()=>{navigate("tenjou")}}>報告する</button>}
        </div>
        <div style={{display:"flex", justifyContent:"space-between",alignItems: "center", marginTop:20}}>
          <p>M.玄関</p>
          {list?.genkanReported ? <button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#ccc", color: "white", borderColor: "#ccc", cursor: "pointer"}} onClick={()=>{navigate("genkan")}}>再報告する</button>:<button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}} onClick={()=>{navigate("genkan")}}>報告する</button>}
        </div>
        <div style={{display:"flex", justifyContent:"space-between",alignItems: "center", marginTop:20}}>
          <p>N.室内建具</p>
          {list?.shitsunaitateguReported ? <button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#ccc", color: "white", borderColor: "#ccc", cursor: "pointer"}} onClick={()=>{navigate("shitsunaitategu")}}>再報告する</button>:<button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}} onClick={()=>{navigate("shitsunaitategu")}}>報告する</button>}
        </div>
        <div style={{display:"flex", justifyContent:"space-between",alignItems: "center", marginTop:20}}>
          <p>O.外壁側サッシ</p>
          {list?.gaihekigawassashiReported ? <button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#ccc", color: "white", borderColor: "#ccc", cursor: "pointer"}} onClick={()=>{navigate("gaihekigawassashi")}}>再報告する</button>:<button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}} onClick={()=>{navigate("gaihekigawassashi")}}>報告する</button>}
        </div>
        <div style={{display:"flex", justifyContent:"space-between",alignItems: "center", marginTop:20}}>
          <p>P.設置物等</p>
          {list?.setsubibutsuReported ? <button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#ccc", color: "white", borderColor: "#ccc", cursor: "pointer"}} onClick={()=>{navigate("setsubibutsu")}}>再報告する</button>:<button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}} onClick={()=>{navigate("setsubibutsu")}}>報告する</button>}
        </div>
        <div style={{display:"flex", justifyContent:"space-between",alignItems: "center", marginTop:20}}>
          <p>Q.小屋裏</p>
          {list?.koyauraReported ? <button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#ccc", color: "white", borderColor: "#ccc", cursor: "pointer"}} onClick={()=>{navigate("koyaura")}}>再報告する</button>:<button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}} onClick={()=>{navigate("koyaura")}}>報告する</button>}
        </div>
        <div style={{display:"flex", justifyContent:"space-between",alignItems: "center", marginTop:20}}>
          <p>R.各階間天井裏・<br/>下屋小屋裏</p>
          {list?.tenjouurakoyauraReported ? <button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#ccc", color: "white", borderColor: "#ccc", cursor: "pointer"}} onClick={()=>{navigate("tenjouurakoyaura")}}>再報告する</button>:<button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}} onClick={()=>{navigate("tenjouurakoyaura")}}>報告する</button>}
        </div>
        <div style={{display:"flex", justifyContent:"space-between",alignItems: "center", marginTop:20}}>
          <p>S.床下基礎立ち<br/>あがりおよび土間</p>
          {list?.yukashitakisoReported ? <button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#ccc", color: "white", borderColor: "#ccc", cursor: "pointer"}} onClick={()=>{navigate("yukashitakiso")}}>再報告する</button>:<button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}} onClick={()=>{navigate("yukashitakiso")}}>報告する</button>}
        </div>
        <div style={{display:"flex", justifyContent:"space-between",alignItems: "center", marginTop:20}}>
          <p>T.床下配管</p>
          {list?.yukashitahaikanReported ? <button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#ccc", color: "white", borderColor: "#ccc", cursor: "pointer"}} onClick={()=>{navigate("yukashitahaikan")}}>再報告する</button>:<button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}} onClick={()=>{navigate("yukashitahaikan")}}>報告する</button>}
        </div>
        <div style={{display:"flex", justifyContent:"space-between",alignItems: "center", marginTop:20}}>
          <p>U.床組、土間等</p>
          {list?.yukagumidomaReported ? <button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#ccc", color: "white", borderColor: "#ccc", cursor: "pointer"}} onClick={()=>{navigate("yukagumidoma")}}>再報告する</button>:<button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}} onClick={()=>{navigate("yukagumidoma")}}>報告する</button>}
        </div>
        <div style={{display:"flex", justifyContent:"space-between",alignItems: "center", marginTop:20}}>
          <p>V.給水</p>
          {list?.kyuusuiReported ? <button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#ccc", color: "white", borderColor: "#ccc", cursor: "pointer"}} onClick={()=>{navigate("kyuusui")}}>再報告する</button>:<button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}} onClick={()=>{navigate("kyuusui")}}>報告する</button>}
        </div>
        <div style={{display:"flex", justifyContent:"space-between",alignItems: "center", marginTop:20}}>
          <p>W.給湯設備</p>
          {list?.kyuuyusetsubiReported ? <button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#ccc", color: "white", borderColor: "#ccc", cursor: "pointer"}} onClick={()=>{navigate("kyuuyusetsubi")}}>再報告する</button>:<button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}} onClick={()=>{navigate("kyuuyusetsubi")}}>報告する</button>}
        </div>
        <div style={{display:"flex", justifyContent:"space-between",alignItems: "center", marginTop:20}}>
          <p>X.排水</p>
          {list?.haisuiReported ? <button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#ccc", color: "white", borderColor: "#ccc", cursor: "pointer"}} onClick={()=>{navigate("haisui")}}>再報告する</button>:<button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}} onClick={()=>{navigate("haisui")}}>報告する</button>}
        </div>
        <div style={{display:"flex", justifyContent:"space-between",alignItems: "center", marginTop:20}}>
          <p>Y.換気設備</p>
          {list?.kankisetsubiReported ? <button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#ccc", color: "white", borderColor: "#ccc", cursor: "pointer"}} onClick={()=>{navigate("kankisetsubi")}}>再報告する</button>:<button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}} onClick={()=>{navigate("kankisetsubi")}}>報告する</button>}
        </div>
        </div>
      
          <div style={{display: "flex", justifyContent: "space-around"}}>
          <button style={{marginTop:50, height: 50, width: 150, borderRadius: 10, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}} onClick={()=>{navigate(`/list`)}}>戻る</button>
          </div>
      
   
        <div style={{marginTop: 50}}></div>
      </div>
    );
  };
    
  export default Report;