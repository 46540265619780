import { useState } from "react"
import { useNavigate } from 'react-router-dom';
import { logout, signin } from "../firebase";

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const Login = () => {
  const navigate = useNavigate()
  const [checked, setChecked] = useState(false);
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  async function handleSignIn() {
    try{
      console.log(email, password)
      await signin(email, password)
      navigate("/list");
      console.log("LOGIN")
    } catch(err){
      console.log(err)
      alert("メールアドレスまたはパスワードが間違っています。")

    }
  }

  async function handleLogout() {
    try{
      await logout()
      console.log("LOGOUT")
    } catch(err){
      alert("Error!")
    }
  }

    return (
        <div className="App">
      <p style={{
            textAlign: "center",
            marginTop: 100,
            color: "#1A1A1A",
            fontWeight: "bold"
          }}>
          検査員ログイン
      </p>

      <div style={{textAlign: "center"}}> 
      <TextField style={{
            width:300,
            height: 50,
            marginTop: 20,
            marginBottom: 20,
            textAlign: "center"
          }} onChange={(e) => setEmail(e.target.value)} placeholder="メールアドレス"/>
      </div>

      <div style={{textAlign: "center"}}> 
      <TextField 
      style={{
            width:300,
            height: 50,
            marginBottom: 20,
            textAlign: "center"
      }} onChange={(e) => setPassword(e.target.value)} type="password" placeholder="パスワード"/>
      </div>

      <div style={{textAlign: "center"}}> 
      <Button 
        style={{
              background: "#0E5C93",
              color: "#fff",
              width:300,
              height: 45,
              marginTop: 40,
              marginBottom: 3}} onClick={handleSignIn}>
        ログイン
      </Button>
      </div>
           
    </div>
    )
}

export default Login

