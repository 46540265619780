import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { db } from "../firebase"
import { getDoc, doc, updateDoc, arrayUnion } from "firebase/firestore"
import responsiveStyles from "../Responsive.module.css"
import { prompt } from '@mobiscroll/react';

const Building = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const buildingId = location.pathname.split("/")[2]
    const listCollectionRef = doc(db, "buildings", buildingId, "details", "inspection1")
    const [list, setList] = useState({})
    const [places1F, setPlaces1F] = useState([])
    const [places2F, setPlaces2F] = useState([])
    const [places3F, setPlaces3F] = useState([])

    useEffect(()=>{
      getList()
      getCustomPlaces()

      async function getList(){
        const data = await getDoc(listCollectionRef)
        let filteredObj = {}
        const obj = data.data()
        for(let key in obj){
           if(key.includes("Inspected")) filteredObj[key] = obj[key]
        }
        setList(filteredObj)
      }

    }, [])

    async function getCustomPlaces(){
      const listCollectionRef = doc(db, "buildings", buildingId)
      const data = await getDoc(listCollectionRef)
      console.log(data.data().places1F,data.data().places2F,data.data().places3F, "data.data().places")
      setPlaces1F(data.data().places1F)
      setPlaces2F(data.data().places2F)
      setPlaces3F(data.data().places3F)
    }

    const addPlace = (floorKey) => {
      const listCollectionRef = doc(db, "buildings", buildingId)
      prompt({
        message: '追加する検査場所名を入力してください。',
        placeholder: '検査場所名',
        okText: "追加",
        cancelText: "キャンセル",
        callback: async function (value) {
          if(value){
            console.log(value);
            if(floorKey==="1f"){
              await updateDoc(listCollectionRef, {
                places1F: arrayUnion({placeName: value})
            });
            } else if(floorKey==="2f"){
              await updateDoc(listCollectionRef, {
                places2F: arrayUnion({placeName: value})
            });
          } else if(floorKey==="3f"){
            await updateDoc(listCollectionRef, {
              places3F: arrayUnion({placeName: value})
          });
        }
        getCustomPlaces()
       };
    }
  }
  )}

    console.log(list, "lists")

    console.log(Object.values(list))

    return (
      <div className={responsiveStyles.frame}>
        <Link to={`/list`}><div style={{textAlign: "left"}}>戻る</div></Link>   
        <p style={{textAlign: "center", fontWeight: "bold"}}>撮影</p>
        <p>撮影作業をおこなっていきます。以下の順序でおこなって下さい。</p>

      {Object.values(list).includes(true) && 
        <div style={{marginTop: 40}}>
        <Link to={`/list/${buildingId}/1/pictuers`}>
            <div style={{textAlign: "right"}}>撮影画像一覧</div>
        </Link>
        </div>}

        <>
        <p style={{textAlign: "center", color: "#808080", marginTop: 20}}>- 室外 -</p>
        <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", marginTop:20}}>
          <p>建物全体<br/><span style={{fontSize:"9px"}}>(報告書表紙用)</span></p>
          {list?.buildingTopInspected ? <button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#ccc", color: "white", borderColor: "#ccc", cursor: "pointer"}} onClick={()=>{navigate("building-top")}}>再撮影する</button>:<button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}} onClick={()=>{navigate("building-top")}}>撮影する</button>}
        </div>
        <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", marginTop:20}}>
          <p>　屋根</p>
          {list?.yaneInspected ? <button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#ccc", color: "white", borderColor: "#ccc", cursor: "pointer"}} onClick={()=>{navigate("yane")}}>再撮影する</button>:<button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}} onClick={()=>{navigate("yane")}}>撮影する</button>}
        </div>
        <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", marginTop:20}}>
          <p>室外北側<br/><span style={{fontSize:"9px"}}>外周部/基礎/外壁/<br/> 閉口部/軒まわり</span></p>
          {list?.shitsugaiTopInspected ? <button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#ccc", color: "white", borderColor: "#ccc", cursor: "pointer"}} onClick={()=>{navigate("shitsugai-top")}}>再撮影する</button>:<button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}} onClick={()=>{navigate("shitsugai-top")}}>撮影する</button>}
        </div>
        <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", marginTop:20}}>
          <p>室外北東側<br/><span style={{fontSize:"9px"}}>全景/隣地境界</span></p>
          {list?.shitsugaiRightTopInspected ? <button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#ccc", color: "white", borderColor: "#ccc", cursor: "pointer"}} onClick={()=>{navigate("shitsugai-right-top")}}>再撮影する</button>:<button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}} onClick={()=>{navigate("shitsugai-right-top")}}>撮影する</button>}
        </div>
        <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", marginTop:20}}>
          <p>室外東側<br/><span style={{fontSize:"9px"}}>外周部/基礎/外壁/<br/> 閉口部/軒まわり</span></p>
          {list?.shitsugaiRightInspected ? <button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#ccc", color: "white", borderColor: "#ccc", cursor: "pointer"}} onClick={()=>{navigate("shitsugai-right")}}>再撮影する</button>:<button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}} onClick={()=>{navigate("shitsugai-right")}}>撮影する</button>}
        </div>
        <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", marginTop:20}}>
          <p>室外南東側<br/><span style={{fontSize:"9px"}}>全景/隣地境界</span></p>
          {list?.shitsugaiRightBottomInspected ? <button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#ccc", color: "white", borderColor: "#ccc", cursor: "pointer"}} onClick={()=>{navigate("shitsugai-right-bottom")}}>再撮影する</button>:<button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}} onClick={()=>{navigate("shitsugai-right-bottom")}}>撮影する</button>}
        </div>
        <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", marginTop:20}}>
          <p>室外南側<br/><span style={{fontSize:"9px"}}>外周部/基礎/外壁/<br/> 閉口部/軒まわり</span></p>
          {list?.shitsugaiBottomInspected ? <button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#ccc", color: "white", borderColor: "#ccc", cursor: "pointer"}} onClick={()=>{navigate("shitsugai-bottom")}}>再撮影する</button>:<button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}} onClick={()=>{navigate("shitsugai-bottom")}}>撮影する</button>}
        </div>
        <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", marginTop:20}}>
          <p>室外南西側<br/><span style={{fontSize:"9px"}}>全景/隣地境界</span></p>
          {list?.shitsugaiLeftBottomInspected ? <button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#ccc", color: "white", borderColor: "#ccc", cursor: "pointer"}} onClick={()=>{navigate("shitsugai-left-bottom")}}>再撮影する</button>:<button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}} onClick={()=>{navigate("shitsugai-left-bottom")}}>撮影する</button>}
        </div>
        <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", marginTop:20}}>
          <p>室外西側<br/><span style={{fontSize:"9px"}}>外周部/基礎/外壁/<br/> 閉口部/軒まわり</span></p>
          {list?.shitsugaiLeftInspected ? <button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#ccc", color: "white", borderColor: "#ccc", cursor: "pointer"}} onClick={()=>{navigate("shitsugai-left")}}>再撮影する</button>:<button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}} onClick={()=>{navigate("shitsugai-left")}}>撮影する</button>}
        </div>
        <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", marginTop:20}}>
          <p>室外北西側<br/><span style={{fontSize:"9px"}}>全景/隣地境界</span></p>
          {list?.shitsugaiLeftTopInspected ? <button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#ccc", color: "white", borderColor: "#ccc", cursor: "pointer"}} onClick={()=>{navigate("shitsugai-left-top")}}>再撮影する</button>:<button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}} onClick={()=>{navigate("shitsugai-left-top")}}>撮影する</button>}
        </div>
        <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", marginTop:20}}>
          <p>機器類</p>
          {list?.kikiruiInspected ? <button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#ccc", color: "white", borderColor: "#ccc", cursor: "pointer"}} onClick={()=>{navigate("kikirui")}}>再撮影する</button>:<button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}} onClick={()=>{navigate("kikirui")}}>撮影する</button>}
        </div>
        <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", marginTop:20}}>
          <p>測定調査</p>
          {list?.sokuteichousaInspected ? <button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#ccc", color: "white", borderColor: "#ccc", cursor: "pointer"}} onClick={()=>{navigate("sokuteichousa")}}>再撮影する</button>:<button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}} onClick={()=>{navigate("sokuteichousa")}}>撮影する</button>}
        </div>

        </>

        <div style={{marginTop: 50}}></div>
        <p style={{textAlign: "center", color: "#808080"}}>- 室内 -</p>

      <div>
        {places1F?.length > 0 && <p style={{textAlign: "left", color: "#808080"}}>1階</p>}
        {places1F?.length > 0 ?
        places1F.map((place, i)=>{
          return (
            <div key={i} style={{display:"flex", justifyContent:"space-between", alignItems:"center", marginTop:20}}>
              <p>{place.placeName}</p>
              {place.status ? <button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#ccc", color: "white", borderColor: "#ccc", cursor: "pointer"}} onClick={()=>{navigate(`shitsunai/1f/${i}`)}}>再撮影する</button>:<button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}} onClick={()=>{navigate(`shitsunai/1f/${i}`)}}>撮影する</button>}
            </div>
          )
        }): <></>}
        {places1F?.length > 0 && 
        <button 
        style={{marginTop: 20}}
        onClick={()=>{addPlace("1f")}} 
        >+</button>}
      </div>  
      

      <div>
      {places2F?.length > 0 && <p style={{textAlign: "left", color: "#808080", marginTop: 30}}>2階</p>}
      {places2F?.length > 0 ?
        places2F.map((place, i)=>{
          return (
            <div key={i} style={{display:"flex", justifyContent:"space-between", alignItems:"center", marginTop:20}}>
              <p>{place.placeName}</p>
              {place.status ? <button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#ccc", color: "white", borderColor: "#ccc", cursor: "pointer"}} onClick={()=>{navigate(`shitsunai/2f/${i}`)}}>再撮影する</button>:<button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}} onClick={()=>{navigate(`shitsunai/2f/${i}`)}}>撮影する</button>}
            </div>
          )
        }): <></>}
        {places2F?.length > 0 && <button style={{marginTop: 20}}  onClick={()=>{addPlace("2f")}} >+</button>}
      </div>

      <div>
      {places3F?.length > 0 && <p style={{textAlign: "left", color: "#808080", marginTop: 30}}>3階</p>}
      {places3F?.length > 0 ?
        places3F.map((place, i)=>{
          return (
            <div key={i} style={{display:"flex", justifyContent:"space-between", alignItems:"center", marginTop:20}}>
              <p>{place.placeName}</p>
              {place.status ? <button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#ccc", color: "white", borderColor: "#ccc", cursor: "pointer"}} onClick={()=>{navigate(`shitsunai/3f/${i}`)}}>再撮影する</button>:<button style={{height: 50, width: 150, borderRadius: 10, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}} onClick={()=>{navigate(`shitsunai/3f/${i}`)}}>撮影する</button>}
            </div>
          )
        }): <></>}
        {places3F?.length > 0 && <button style={{marginTop: 20}}  onClick={()=>{addPlace("3f")}} >+</button>}
        </div>

          <div style={{display: "flex", justifyContent: "space-around"}}>
            <button style={{marginTop:50, height: 50, width: 150, borderRadius: 10, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}} onClick={()=>{navigate("/list")}}>戻る</button>
          </div>
        <div style={{marginTop: 50}}></div>
      </div>
    );
  };
    
  export default Building;