import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { db } from "../firebase"
import { getDoc ,getDocs, doc, collection } from "firebase/firestore"
import ReactLoading from 'react-loading';
import responsiveStyles from "../Responsive.module.css"
import {delay} from "./utils/delay"


const Pictuers = () => {
  const navigate = useNavigate()
  const location = useLocation();
  const buildingId = location.pathname.split("/")[2]

  const [loadingFlag, setLoadingFlag] = useState(false)
  const [data, setData] = useState([])
  const [customPlacesdata, setCustomPlaceData] = useState([])

  useEffect(()=>{
    getTakenPictures()
    getCustomPlacesTakenPictrues()
  }, [])

  async function getTakenPictures(){
    setLoadingFlag(true)
    const picturesTitle = ["yane", "gaishubu", "kiso", "gaiheki", "heikoubu", "nokimawari", "zenkei", "rinchikyoukai", "kikirui", "sokuteichousa"]
    let result = [];
    picturesTitle.forEach(async (title)=>{
      const inspection1CollectionRef = collection(db, "buildings", buildingId, "details", "inspection1", title)
      const querySnapshot = await getDocs(inspection1CollectionRef)
      let placeholder = []
      querySnapshot.forEach((doc)=>{
        const data = doc.data()
        placeholder.push(data)
      })
      result.push({placeName: title, data: placeholder})
    })

    await delay(1.5)
    console.log(result, "result")
    setData(result)
    setLoadingFlag(false)
  }

  async function getCustomPlacesTakenPictrues(){
    const buildingRef =  doc(db, "buildings", buildingId)
    const data = await getDoc(buildingRef)
    let result = []
    const places1F = data.data().places1F
    const places2F = data.data().places2F
    const places3F = data.data().places3F
    const placesArr = [places1F,places2F,places3F]
  
    placesArr.forEach((place)=>{
      if(place){
        result = [...result,...place]
      }
    })
    setCustomPlaceData(result)
  }

  console.log(customPlacesdata, "customPlacesdata")

  function getName(title){
    if(title==="yane"){
      return "屋根" 
    } else if(title==="gaishubu"){
      return "外周部" 
    } else if(title==="kiso"){
      return "基礎" 
    } else if(title==="gaiheki"){
      return "外壁" 
    } else if(title==="heikoubu"){
      return "閉口部" 
    } else if(title==="nokimawari"){
      return "軒まわり" 
    } else if(title==="zenkei"){
      return "全景" 
    } else if(title==="rinchikyoukai"){
      return "隣地境界" 
    } else if(title==="kikirui"){
      return "機器類" 
    } else if(title==="sokuteichousa"){
      return "測定調査" 
    }
  }

  console.log(data)

  return (
    <>
    {loadingFlag ? <ReactLoading type="bubbles" color="#A9A9A9" height={"5%"} width={"80%"} />: 
    <div className={responsiveStyles.frame}>
    <div onClick={()=>{navigate(-1)}} style={{textAlign: "left"}}><p style={{color: "blue", cursor: "pointer"}}>＜ 戻る</p></div>

    <div style={{fontWeight: "bold"}}>撮影画像一覧</div>

    {data.length > 0 ? 
    data.map((com,i)=>{
      return(
      <div key={com.placeName}>
        <p style={{marginTop: 25,fontWeight: "bold"}}>{getName(com.placeName)}</p>
        {com.hasOwnProperty("data") ?
         com.data.map((d)=>{
          return(
            <div key={d.id} style={{marginTop: 7 }}>
              <img src={d.url} width="80%"/>
            </div>
          )
        }): <p>写真がありません。</p>}
      </div>  
      ) 
    }):<>撮影された画像はありません。</>}

  {customPlacesdata.length > 0 ? 
    customPlacesdata.map((place)=>{
      return(
      <div key={place.id}>
        <p style={{marginTop: 25,fontWeight: "bold" }}>{place.placeName}</p>
        {place.hasOwnProperty("imageUrl") ? 
        place.imageUrl.map((url, i)=>{
          return(
            <div key={place.id+i} style={{marginTop: 7 }}>
              <img src={url} width="80%"/>
            </div>
          )
        }):<p>写真がありません。</p>}
      </div>  
      ) 
    }):<></>} 

    <div>
    <button style={{height:50,width: 125,marginTop:50, borderRadius: 15, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}}
          onClick={()=>{
           navigate(-1)
    }}>戻る</button>
    </div>

    <div style={{marginTop: 50}}></div>
    </div>    
    }
    </> 
  )
}

export default Pictuers