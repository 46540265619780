import React from 'react';
import { Link } from "react-router-dom";
import Logo  from "../images/zenjushin-logo.png"
import { useAuthContext } from '../AuthProvider';
import { logout } from "../firebase"
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const MyNavbar = () => {
    const { user } = useAuthContext();
    console.log(user)

    const handleLogout = async () => {
      try{
        await logout()
      }catch(error){
        console.log(error)
      }
    }

    return (
      <>
      {user ? 
      <Navbar collapseOnSelect expand="lg" bg="light" variant="light" style={{ marginBottom: 25}}>
        <Container>
          <Nav>
            <img src={ Logo } alt="company-logo" height="50px"/>
          </Nav>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <NavDropdown title={user?.email} id="collasible-nav-dropdown">
              <NavDropdown.Item onClick={handleLogout}>ログアウト</NavDropdown.Item>
            </NavDropdown>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      :
      <nav>
          <div style={{textAlign: "center", marginTop: 10}}>
          <Link to="/list">
            <img src={ Logo } alt="company-logo" height="55px"/>
          </Link>
          </div>
        </nav>
      }
      </>
    );
  };
    
  export default MyNavbar;