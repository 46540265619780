import React, { useEffect } from 'react';
import { Stage, Layer, Image, Circle, Transformer } from 'react-konva';
import useImage from 'use-image';


const HImage = ({img, width, setScale, setStageHeight}) => {
  const [image] = useImage(img);
  const scaleX = width / image?.width 
  setScale(scaleX)
  setStageHeight(scaleX * image?.height)
  return <Image image={image} />;
};

const CustomCircle = ({ shapeProps, isSelected, onSelect, onChange, func }) => {
  const shapeRef = React.useRef();
  const trRef = React.useRef();

  React.useEffect(() => {
    if (isSelected) {
      // we need to attach transformer manually
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  return (
    <React.Fragment>
      <Circle
        onClick={onSelect}
        onTap={onSelect}
        ref={shapeRef}
        {...shapeProps}
        draggable
        onDragEnd={(e) => {
          const node = shapeRef.current;
          onChange({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
          });
          func(e.target.x(), e.target.y(),node.scaleX(), node.scaleY(),  node.rotation())
        }}
        onTransformEnd={(e) => {
          // transformer is changing scale of the node
          // and NOT its width or height
          // but in the store we have only width and height
          // to match the data better we will reset scale on transform end
          const node = shapeRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();

          // we will reset it back
          //node.scaleX(1);
          //node.scaleY(1);
          onChange({
            ...shapeProps,
            x: node.x(),
            y: node.y(),
            // set minimal value
            width: Math.max(node.width() + scaleX),
            height: Math.max(node.height() + scaleY),
            scaleX: scaleX,
            scaleY: scaleY,
            rotation: node.rotation()
          });
          func(e.target.x(), e.target.y(),scaleX, scaleY,  node.rotation())
        }}
      />
      {isSelected && (
        <Transformer
          ref={trRef}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </React.Fragment>
  );
};

function generateInitialRectangles(circleX, circleY, scaleX, scaleY, circleRotation){
  const initialRectangles = [
    {
      stroke: 'red',
      id: 'rect1',
    },
  ];
  if(circleX && circleY){
    initialRectangles[0].x = circleX
    initialRectangles[0].y = circleY
    initialRectangles[0].scaleX = scaleX
    initialRectangles[0].scaleY = scaleY
    initialRectangles[0].circleRotation = circleRotation
  }
  return initialRectangles
}

const PinCircle = ({image, setFile, id, name, circleX, circleY, scaleX, scaleY, circleRotation}, ref2) => {
  const [rectangles, setRectangles] = React.useState(generateInitialRectangles(circleX, circleY, scaleX, scaleY, circleRotation));
  const [selectedId, selectShape] = React.useState(null);
  const stageRef = React.useRef(null);
  const containerRef  = React.useRef(null);
  const [stageWidth, setStageWidth] = React.useState(250);
  const [stageHeight, setStageHeight] = React.useState(300);
  const [scale, setScale] = React.useState(0.3)

  React.useImperativeHandle(ref2, () => ({
    parentprocess2: () => {
        process()
    }
}))
  
  React.useEffect(()=>{
    let width = containerRef.current.offsetWidth
    setStageWidth(width)
    setStageHeight(window.innerHeight)
  },[containerRef])

  const checkDeselect = (e) => {
      selectShape(null);
  };

  const process = async (x, y, scaleX, scaleY, rotation) => {
    checkDeselect()
    const temp = stageRef.current;

    // stageRefの中身(temp )がnullな可能性を考慮してチェック
    if (temp !== null) {
    console.log("temp")
 
    temp.absolutePosition({
        x: 0,
        y: 0
      });
      // dataUrlに、画像データがdata URL(MIME Type + base64文字列)形式で書き込まれる。
      // toDataURLの引数を変更すれば、PNG以外の画像形式への変換も可能
      const dataUrl = temp.toDataURL();
      console.log(dataUrl, "dataUrl")
      var data = atob( dataUrl.substring( "data:image/png;base64,".length ) ),
    asArray = new Uint8Array(data.length);

    for( var i = 0, len = data.length; i < len; ++i ) {
        asArray[i] = data.charCodeAt(i);    
    }

    var blob = new Blob( [ asArray.buffer ], {type: "image/png"} );
    await setFile(name, id, blob, x, y, scaleX, scaleY, rotation)
    }
  };

  return (
    <div ref={containerRef} >
    <Stage
     scale={{ x: scale, y: scale }}
      width={stageWidth}
      height={stageHeight}
      ref={stageRef}
    >
      <Layer>
      <HImage img={image} width={stageWidth} height={stageHeight} setScale={setScale} setStageHeight={setStageHeight} />
        {rectangles.map((rect, i) => {
          return (
            <CustomCircle
              key={i}
              shapeProps={{x: stageWidth/scale/2, y:stageHeight/scale/2, width: stageWidth/scale/2, height: stageHeight/scale/2, strokeWidth: 3/scale, ...rect }}
              isSelected={rect.id === selectedId}
              onSelect={() => {
                selectShape(rect.id);
              }}
              onChange={(newAttrs) => {
                const rects = rectangles.slice();
                rects[i] = newAttrs;
                setRectangles(rects);
              }}
              func={process}
            />
          );
        })}
      </Layer>
    </Stage>
    </div>
  );
};

export default React.forwardRef(PinCircle);