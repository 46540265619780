import React, {useState, useEffet, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDoc, addDoc, updateDoc, doc, query, getDocs, where } from "firebase/firestore"
import { db, upload } from "../firebase"
import { useAuthContext } from '../AuthProvider';
import Sample  from "../images/sample.png"
import responsiveStyles from "../Responsive.module.css"
import TopicIcon from '@mui/icons-material/Topic';

const Top = () => {
    const navigate = useNavigate()
    const { user } = useAuthContext();

    const [buildings, setBuildings] = useState([]) 

    useEffect(()=>{
      getTaskLists()

      async function getTaskLists(){
        const result = []
        if(user){
          if(user.uid==="zxnt6ypqsxblfa7kgbE00OJcMfM2"){ //inspection-admin@gmail.comアカウントで全ての物件を見れる
            const q2 = collection(db, "buildings");
            const querySnapshot2 = await getDocs(q2);
            querySnapshot2.forEach((doc) => {
              result.push({id: doc.id,...doc.data()})
            })
            setBuildings(result)
          }else{
          // ログインユーザーからworkerIdを特定
          const q = query(collection(db, "workers"), where("mailAddress", "==", user.email));
          const querySnapshot = await getDocs(q);        
            const workerId = querySnapshot.docs[0].id
          // workerIdをアサインしている物件を取得
            const q2 = query(collection(db, "buildings"), where("workerId", "==", workerId));
            const querySnapshot2 = await getDocs(q2);
            querySnapshot2.forEach((doc) => {
              result.push({id: doc.id,...doc.data()})
            })
            setBuildings(result)
             }
      }
      }
    }, [])


    const [inspectionStatus, setInspectionStatus] = useState(false)

    console.log(buildings, "LIST")
    return (
      <div className={responsiveStyles.frame}>
      <p style={{marginTop: 20,textAlign: "center", fontWeight: "bold"}}>担当物件一覧</p>
      <div style={{display: "flex", justifyContent: "center", marginTop: 30}}>
        <button onClick={()=>{setInspectionStatus(false)}}  style={{background: inspectionStatus ?  "#fff" : "#299fd6" ,color: inspectionStatus ? "#299fd6": "#fff" ,width: 100,height: 30,borderColor:  inspectionStatus ? "#fff":"#299fd6", cursor: "pointer", borderRadius: 50, border: inspectionStatus ? "solid 1px #299fd6": "none"}}><span  style={{fontSize: 16}}>未検査</span></button>
        <button onClick={()=>{setInspectionStatus(true)}} style={{background: inspectionStatus ? "#299fd6": "#fff" ,color: inspectionStatus ? "#fff" : "#299fd6",width: 100,height: 30,borderColor:  inspectionStatus ? "#299fd6": "#fff", cursor: "pointer", borderRadius: 50, border: inspectionStatus ? "none": "solid 1px #299fd6"}}><span style={{fontSize: 16}}>検査済</span></button>

      </div>
      {buildings.length > 0 ?
      buildings.filter(building=>{
        return building.isFinished === inspectionStatus
      }).map((building)=>{
        return (
          <div key={building.id} style={{marginTop: 50,textAlign: "center"}}>
            <div style={{textAlign: "right", cursor: "pointer"}} onClick={()=>{navigate(`/list/${building.id}/documents`)}}><TopicIcon /></div>
            {building.buildingPicture ? <img src={building.buildingPicture?.src} width="100%" style={{marginBottom: 5}}/>: <img src={Sample} width="55%" style={{marginBottom: 20}}/>}
            {/* building.pictures ? <div style={{textAlign: "right", fontSize: 12}}><button onClick={handleOpen}>図面</button></div> : <></> */}
            <p>物件所在地: {building.address}</p>
            <p>依頼者: {building.clientName}さま</p>
            {building.isFinished ?
            <div style={{display: "flex", justifyContent: "space-evenly"}}>
            <button style={{height:40,width: 150, borderRadius: 15, marginBottom: 5, backgroundColor: "#ccc", color: "white", borderColor: "#ccc"}}
              onClick={()=>{navigate(`/list/${building.id}/1/pictuers`)}}
            >撮影画像一覧</button>
            <button style={{height:40,width: 150, borderRadius: 15, marginBottom: 5, backgroundColor: "#ccc", color: "white", borderColor: "#ccc"}}
              onClick={()=>{navigate(`/list/${building.id}/2/problems`)}}
            >不具合報告一覧</button>
            </div> : 
            <>
            <div style={{display: "flex", justifyContent: "space-evenly"}}>
            <button style={{height:40,width: 150, borderRadius: 15, marginBottom: 5, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93"}}
              onClick={()=>{navigate(`/list/${building.id}/1`)}}
            >撮影</button>
            <button style={{height:40,width: 150, borderRadius: 15, marginBottom: 5, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93"}}
              onClick={()=>{navigate(`/list/${building.id}/2`)}}
            >調査報告</button>
            </div>
            <div style={{display: "flex", justifyContent: "space-evenly"}}>
            <button style={{height:40,width: 150, borderRadius: 15, marginBottom: 5, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93"}}
              onClick={()=>{navigate(`/list/${building.id}/3`)}}
            >ポインティング</button>
            <button style={{height:40,width: 150, borderRadius: 15, marginBottom: 5, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93"}}
              onClick={()=>{navigate(`/list/${building.id}/4`)}}
            >完了報告</button>
            </div>
            </>
             }
          </div>
        )
      })
      
      :<></>}
      <div style={{marginTop: 50}}></div>
      
      </div>
    );
  };
    
  export default Top;