import React from 'react';
import '../lib/css/mobiscroll.react.min.css';
import { Eventcalendar, getJson } from '@mobiscroll/react';

const ShiftForm = () => {

    const [myEvents, setEvents] = React.useState([]);

    React.useEffect(() => {
        /*
        getJson('https://trial.mobiscroll.com/events/?vers=5', (events) => {
            setEvents(events);
        }, 'jsonp');
        */
    }, [myEvents]);

    console.log(myEvents, "myEvents")
    
    const responsive = React.useMemo(() => {
        return {
            xsmall: {
                view: {
                    schedule: { type: 'day' }
                }
            },
            custom: { // Custom breakpoint
                breakpoint: 600,
                view: {
                    schedule: { type: 'week' }
                }
            }
        };
    }, []);

    const newEventData = (e) => {
        console.log(e, "EE")
        return (
            {
                color: 'blue',
                title: '稼働可能',
            }
        )
    }

    return (
        <div style={{margin: "0 1%"}}>
           <p style={{textAlign: "center", fontWeight: "bold"}}>シフト表</p> 
        {/*    
        <button onClick={()=>{
            console.log("CALLED")
            setEvents(myEvents)
            console.log(myEvents)
            }}>保存</button>*/}
        <Eventcalendar
            theme="ios" 
            themeVariant="light"
            clickToCreate={true}
            extendDefaultEvent={newEventData}
            dragToCreate={true}
            dragToMove={true}
            dragToResize={true}
            data={myEvents}
            responsive={responsive}
       />
       </div>
    ); 
}

export default ShiftForm;