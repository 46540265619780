import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { db, upload, storage } from "../firebase"
import { updateDoc,setDoc, doc, getDoc } from "firebase/firestore"
import ReactLoading from 'react-loading';
import { ref, getDownloadURL } from "firebase/storage";
import {delay} from "./utils/delay"
import loadImage from 'blueimp-load-image';

import NoImage from "../images/no_img.png"

import styles from "../Arrow.module.css"
import responsiveStyles from "../Responsive.module.css"

const ShitsugaiRightTop = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const buildingId = location.pathname.split("/")[2]
    const [pageFlag, setPageFlag] = useState()
    const [files, setFiles] = useState({})
    const [loadingFlag, setLoadingFlag] = useState(false)
    const [isChanged, setIsChanged] = useState(false)
    const [isFileChanged, setIsFileChanged] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [floorImg, setFloorImg] = useState({})

    useEffect(()=>{
      getPictures()
      getFloorImg()

      async function getPictures(){
        const result = {}
        const placeTitles = ["zenkei", "rinchikyoukai"]
        await placeTitles.forEach(async (placeTitle, i) =>{
          const docRef = doc(db, "buildings", buildingId, "details", "inspection1", placeTitle, "1");
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            console.log(docSnap.data(), "docSnap")
            result[i+1] = docSnap.data().url
          }
        })

        setFiles(result)
        setLoadingFlag(true)
        await delay(1)
        setLoadingFlag(false)
        if(Object.keys(result).length>0){
          setPageFlag(3)
          setIsEdit(true)
        }else {
          setPageFlag(1)
        }
      }

      async function getFloorImg(){
        const listCollectionRef = doc(db, "buildings", buildingId)
        const data = await getDoc(listCollectionRef)
        setFloorImg(data.data().floorImage1F)
      }
  
  },[])

    const onFileInputChange = async (e) => {
    setIsChanged(true)
    setIsFileChanged(true)
    
    const canvas = await loadImage(e.target.files[0], {
      maxWidth: 550,
      canvas: true,
    });
    canvas.image.toBlob((blob) => {
      files[e.target.id] = blob
      setFiles({...files})
    }, e.target.files[0].type);
  };

    const updateInspectedStatus = async () => {
      const buildingDoc = doc(db, "buildings", buildingId, "details", "inspection1")
      await updateDoc(buildingDoc, {shitsugaiRightTopInspected: true})
    }

    const updateReport = async () => {
      Object.keys(files).forEach(async (fileId)=>{
        if(typeof files[fileId] !== "string"){
          await setDoc(doc(db, "buildings", buildingId, "details", "inspection1", placeTitle(fileId), "1"), {id: "1", name: placeTitle(fileId)+"-"+fileId})   

          await upload(files[fileId], `${buildingId}/${placeTitle(fileId)}/1`)
          const storageRef = ref(storage, `${buildingId}/${placeTitle(fileId)}/1`);
          getDownloadURL(storageRef)
          .then(async (url) => {
            await updateDoc(doc(db, "buildings", buildingId, "details", "inspection1", placeTitle(fileId), "1"), {url: url})
          })  
        }  
      })
      await delay(3)
  }

  function placeTitle(fileId){
    if(fileId==="1"){
      return "zenkei"
    } else if(fileId==="2"){
      return "rinchikyoukai"
    }
  }
    
    return (
      <>
      {loadingFlag ? <ReactLoading type="bubbles" color="#A9A9A9" height={"5%"} width={"80%"} />: 
       <div className={responsiveStyles.frame}>
      {pageFlag === 1 &&
      <div>
        <p style={{fontWeight: "bold"}}>室外北東側 ①</p> 
        <p style={{fontWeight: "bold"}}>全景</p> 
        <p>全景の写真撮影をおこなっていきます。図面の三角矢印の方向を向いて撮影して下さい。</p> 

        <div className={styles.square_triangle_arrow_right_top}>
        <img src={floorImg?.src ? floorImg.src : NoImage} width="80%"/>
        </div>

        <div style={{marginTop: 30}}>
        <p style={{marginBottom: 50}}>画像をアップロードしてください。</p> 
        <label style={{padding: "10px 20px", color: "#ffffff", backgroundColor: "#696969", cursor: "pointer", borderRadius: 10}}>
           ファイルを選択
           <input id="1" type="file" accept="image/*" style={{display: "none"}} onChange={onFileInputChange}/>
        </label>
        </div>       

        <div  style={{marginTop: 30}}>
          {files["1"] ?
          <>
            <img src={typeof files["1"] === "string" ? files["1"] : window.URL.createObjectURL(files["1"])} alt="yane_1" width="80%"/>
            <p style={{color: "#c0c0c0", fontSize: "14px"}}>{files["1"]?.name}</p>
          </>
          : <></>}
        </div>

        {isEdit ? 
        <>
          <button style={{height:50,width: 125,marginTop:50, borderRadius: 15, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}}
          onClick={()=>{
          setIsFileChanged(false) 
          setPageFlag(3)
          }}>{isFileChanged ? "完了":"戻る"} </button>
        </>:
        <div style={{display: "flex", justifyContent: "space-around"}}>
         <button style={{height:50,width: 125,marginTop:50, borderRadius: 15, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}}
          onClick={()=>{navigate(-1)}}>戻る</button>
         <button style={{height:50,width: 125,marginTop:50, borderRadius: 15, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}}
         onClick={()=>{setPageFlag(2)}}>次へ</button>
        </div>
        }
        <div style={{marginTop: 50}}></div>  
      </div>
      }
      {pageFlag === 2 &&
      <div>
        <p style={{fontWeight: "bold"}}>室外北東側 ②</p> 
        <p style={{fontWeight: "bold"}}>隣地境界</p> 
        <p>隣地境界の写真撮影をおこなっていきます。図面の三角矢印の方向を向いて撮影して下さい。</p> 
  
        <div className={styles.square_triangle_arrow_right_top}>
        <img src={floorImg?.src ? floorImg.src : NoImage} width="80%"/>
        </div>

        <div style={{marginTop: 30}}>
        <p style={{marginBottom: 50}}>画像をアップロードしてください。</p> 
        <label style={{padding: "10px 20px", color: "#ffffff", backgroundColor: "#696969", cursor: "pointer", borderRadius: 10}}>
           ファイルを選択
           <input id="2" type="file" accept="image/*" style={{display: "none"}} onChange={onFileInputChange}/>
        </label>
        </div>

        <div  style={{marginTop: 30}}>
          {files["2"] ?
          <>
            <img src={typeof files["2"] === "string" ? files["2"] : window.URL.createObjectURL(files["2"])} alt="yane_2" width="80%"/>
            <p style={{color: "#c0c0c0", fontSize: "14px"}}>{files["2"]?.name}</p>
          </>
          : <></>}
        </div>

        {isEdit ? 
        <>
          <button style={{height:50,width: 125,marginTop:50, borderRadius: 15, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}}
          onClick={()=>{
            setIsFileChanged(false)
            setPageFlag(3)
            }}>{isFileChanged ? "完了":"戻る"} </button>
        </>:
        <div style={{display: "flex", justifyContent: "space-around"}}>
         <button style={{height:50,width: 125,marginTop:50, borderRadius: 15, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}}
          onClick={()=>{setPageFlag(1)}}>戻る</button>
         <button style={{height:50,width: 125,marginTop:50, borderRadius: 15, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}}
         onClick={()=>{setPageFlag(3)}}>次へ</button>
        </div>
        }
        <div style={{marginTop: 50}}></div>  
      </div>
      }
      
      {pageFlag === 3 &&
      <div>
        <p style={{fontWeight: "bold"}}>写真確認</p> 
        <p>室外北東側の撮影が終わりました。以下の画像で間違いないかご確認ください。</p> 
        <p style={{fontWeight: "bold"}}>全景</p> 
        {files["1"] ? <div onClick={()=>{setPageFlag(1)}}><img src={typeof files["1"] === "string" ? files["1"] : window.URL.createObjectURL(files["1"])} alt="yane_1" width="80%"/></div>: <div onClick={()=>{setPageFlag(1)}}><img src={NoImage} alt="no-image"/></div>}
        <div style={{marginBottom: 15}}></div>
        <p style={{fontWeight: "bold"}}>隣地境界</p> 
        {files["2"] ? <div onClick={()=>{setPageFlag(2)}}><img src={typeof files["2"] === "string" ? files["2"] : window.URL.createObjectURL(files["2"])} alt="yane_1" width="80%"/></div>: <div onClick={()=>{setPageFlag(2)}}><img src={NoImage} alt="no-image"/></div>}
        <div style={{marginBottom: 15}}></div>

        {isEdit ? 
        <>
          {isChanged ?
          <button style={{height:50,width: 125,marginTop:50, borderRadius: 15, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}}
          onClick={async ()=>{
           setLoadingFlag(true)
            await updateReport()
            setLoadingFlag(false)
            navigate(-1)
            }}>完了</button>: 
          <button style={{height:50,width: 125,marginTop:50, borderRadius: 15, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}}
          onClick={()=>{navigate(-1)}}>戻る</button>
          }
        </>:
        <div style={{display: "flex", justifyContent: "space-around"}}>
         <button style={{height:50,width: 125,marginTop:50, borderRadius: 15, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}}
          onClick={()=>{setPageFlag(2)}}>戻る</button>
         <button style={{height:50,width: 125,marginTop:50, borderRadius: 15, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}}
         onClick={async ()=>{
          setLoadingFlag(true)
           await updateInspectedStatus()
           await updateReport()
           setLoadingFlag(false)
           navigate(-1)
           }}>完了</button>
        </div>
        }
        <div style={{marginTop: 50}}></div>  
      </div>
      }
      </div>}</>
    );
  };
    
  export default ShitsugaiRightTop;